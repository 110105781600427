import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function DomichainIcon() {
  return (
    <img
      src="https://i.imgur.com/liyd6YY.png"
      style={{ width: 30, height: 30 }}
    ></img>
  );
}
