import { makeStyles } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { useReducer } from 'react';
import BalancesList from '../components/BalancesList';
import { TransactionHistory } from '../components/TransactionHistory';
import { useIsExtensionWidth } from '../utils/utils';
import { useQueryClient } from '@tanstack/react-query';
import { useWallet } from '../utils/wallet';

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down(theme.ext)]: {
      padding: 0,
    },
    [theme.breakpoints.up(theme.ext)]: {
      maxWidth: 'md',
    },
  },
  balancesContainer: {
    [theme.breakpoints.down(theme.ext)]: {
      marginBottom: 24,
    },
  },
}));

export default function WalletPage() {
  const classes = useStyles();
  const isExtensionWidth = useIsExtensionWidth();
  const queryClient = useQueryClient();

  const invalidateQueries = () => {
    queryClient.invalidateQueries();
  };

  return (
    <Container fixed maxWidth="md" className={classes.container}>
      <Grid container spacing={isExtensionWidth ? 0 : 3}>
        <Grid item xs={12} className={classes.balancesContainer}>
          <BalancesList onRefreshClick={invalidateQueries} />
          <TransactionHistory />
        </Grid>
      </Grid>
    </Container>
  );
}
