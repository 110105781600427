import { useQuery } from '@tanstack/react-query';
import { useConnection } from '../connection';
import { useWallet } from '../wallet';
import { AccountInfo, ParsedAccountData, PublicKey } from '@solana/web3.js';

export type TokenAccount = {
  pubkey: PublicKey;
  account: AccountInfo<ParsedAccountData>;
};

export function useTokenAccounts(programId: PublicKey) {
  const wallet = useWallet();
  const connection = useConnection();

  return useQuery<TokenAccount[]>({
    queryKey: [
      'tokenAccounts',
      wallet.publicKey,
      connection.rpcEndpoint,
      programId,
    ],
    queryFn: async () => {
      const tokenAccounts = await connection.getParsedTokenAccountsByOwner(
        wallet.publicKey,
        { programId },
      );
      return tokenAccounts.value;
    },
    refetchInterval: 30 * 1000,
  });
}
