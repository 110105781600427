import { isExtension } from './utils';

const STORAGE_KEY = 'CHROME_STORAGE_POLYFILL';
const ON_CHANGED_EVENT_TYPE = 'CHROME_STORAGE_POLYFILL_onChanged';

let registeredListeners = [];

const chromeStoragePolyfill = {
  get(keys, callback) {
    const rawStorageValue = localStorage.getItem(STORAGE_KEY);
    if (typeof rawStorageValue !== 'string') {
      localStorage.setItem(STORAGE_KEY, '{}');
    }

    const storageValue =
      typeof rawStorageValue === 'string' ? JSON.parse(rawStorageValue) : {};

    if (typeof keys === 'string') {
      keys = [keys];
    }

    return callback?.(
      Object.fromEntries(
        keys
          .filter((key) => key in storageValue)
          .map((key) => [key, storageValue[key]]),
      ),
    );
  },
  set(value, callback) {
    if (typeof value !== 'object') {
      throw new Error('Unsupported storage value');
    }

    const rawStorageValue = localStorage.getItem(STORAGE_KEY);
    if (typeof rawStorageValue !== 'string') {
      localStorage.setItem(STORAGE_KEY, '{}');
    }

    const storageValue =
      typeof rawStorageValue === 'string' ? JSON.parse(rawStorageValue) : {};

    localStorage.setItem(
      STORAGE_KEY,
      JSON.stringify(Object.assign(storageValue, value)),
    );

    setImmediate(() =>
      window.dispatchEvent(
        new CustomEvent(ON_CHANGED_EVENT_TYPE, {
          detail: { changes: value },
        }),
      ),
    );

    return callback?.();
  },
  clear(callback) {
    localStorage.clear();
    callback?.();
  },
  onChanged: {
    addListener(callback) {
      const listener = (event) => callback?.(event.detail.changes);
      window.addEventListener(ON_CHANGED_EVENT_TYPE, listener);
      registeredListeners.push({ listener, callback });
    },

    removeListener(callback) {
      const registeredListener = this.listeners.find(
        (l) => l.callback === callback,
      );

      if (registeredListener?.listener) {
        window.removeEventListener(
          ON_CHANGED_EVENT_TYPE,
          registeredListener.listener,
        );

        registeredListeners = registeredListeners.filter(
          (l) => l.callback !== callback,
        );
      }
    },
  },
};

export const chromeLocalStorage = isExtension
  ? window.chrome.storage.local
  : chromeStoragePolyfill;
