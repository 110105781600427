import {
  DialogContentText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import strings from '../../../localization';
import { useWallet } from '../../../utils/wallet';
import LoadingIndicator from '../../LoadingIndicator';
import { DelegateStake } from './DelegateStake';
import DepositInfo from './DepositInfo';
import StakingSettingsDialog from '../StakingSettingsDialog';
import { Box } from '@mui/material';

export type StakingPlan = {
  id: number;
  name: {
    en: string;
    zh: string;
    ko: string;
  };
  priceInUsdt: number;
  extraDomiReward?: number;
  annualRoiPercentage: number;
  stakingLimit: number;
};

const FREE_STAKING_VOTE_PUBKEY = '67hhawRYjPHfcSWM7eeqKSCA8d4Toqi3oYEomHYw6J7S';
const FREE_STAKING_LIMIT = 5;

const FREE_STAKING_PLAN: StakingPlan = {
  id: -111,
  name: {
    en: 'Starter',
    zh: '启动器',
    ko: '스타터',
  },
  priceInUsdt: 0,
  annualRoiPercentage: 22,
  stakingLimit: FREE_STAKING_LIMIT,
};

export default function ActivateStake({
  stakingBackendUrl,
  onClose,
}: {
  stakingBackendUrl: string;
  onClose: () => void;
}) {
  const wallet = useWallet();
  const [selectedPlan, setSelectedPlan] = useState<StakingPlan>();

  const { data: stakingPlans } = useQuery({
    queryKey: ['stakingPlans', stakingBackendUrl],
    queryFn: async () => {
      const response = await fetch(`${stakingBackendUrl}/v1/plans`);
      const data = await response.json();
      return data.data.plans as StakingPlan[];
    },
  });

  const { data: validatorInfo } = useQuery({
    queryKey: [
      'assignedValidator',
      wallet.publicKey?.toBase58(),
      stakingBackendUrl,
    ],
    queryFn: async () => {
      const url = new URL(`${stakingBackendUrl}/v1/validator`);
      url.searchParams.append('domichainAddress', wallet.publicKey?.toBase58());

      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('No assigned validator yet');
      }

      const data = await response.json();
      return data.data;
    },
    retry: true,
    retryDelay: 3000,
  });

  if (
    selectedPlan === FREE_STAKING_PLAN ||
    typeof validatorInfo !== 'undefined'
  ) {
    return (
      <DelegateStake
        votePubkey={validatorInfo?.votePubkey ?? FREE_STAKING_VOTE_PUBKEY}
        stakingLimit={validatorInfo?.stakingLimit ?? FREE_STAKING_LIMIT}
        stakingBackendUrl={stakingBackendUrl}
        onClose={onClose}
      />
    );
  }

  if (typeof selectedPlan !== 'undefined') {
    return (
      <DepositInfo
        key={String(selectedPlan)}
        stakingPlan={selectedPlan}
        stakingBackendUrl={stakingBackendUrl}
        onClose={() => setSelectedPlan(undefined)}
      />
    );
  }

  return (
    <>
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>{strings.startStaking}</Box>
          <Box>
            <StakingSettingsDialog />
          </Box>
        </Box>
      </DialogTitle>

      {stakingPlans ? (
        <DialogContent>
          <DialogContentText>
            {strings.stakingChoosePlanDescription}
          </DialogContentText>

          <TableContainer component={Paper} variant="outlined">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">{strings.stakingPlan}</TableCell>
                  <TableCell align="center">
                    {strings.stakingAnnualFee}
                  </TableCell>
                  <TableCell align="center">{strings.stakingProduct}</TableCell>
                  <TableCell align="center">
                    {strings.stakingAnnualRoi}
                  </TableCell>
                  <TableCell align="center">{strings.stakingLimit}</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {[FREE_STAKING_PLAN, ...stakingPlans].map((stakingPlan) => (
                  <TableRow
                    key={stakingPlan.id}
                    onClick={() => setSelectedPlan(stakingPlan)}
                  >
                    <TableCell align="center">
                      {stakingPlan.name[strings.getLanguage()]}
                    </TableCell>
                    <TableCell align="center">
                      {stakingPlan.priceInUsdt > 0
                        ? `${stakingPlan.priceInUsdt} USDT`
                        : strings.free}
                    </TableCell>
                    <TableCell align="center">
                      {stakingPlan.extraDomiReward
                        ? `${strings.validator} + ${stakingPlan.extraDomiReward} DOMI`
                        : strings.validator}
                    </TableCell>
                    <TableCell align="center">
                      {stakingPlan.annualRoiPercentage}%
                    </TableCell>
                    <TableCell align="center">
                      {stakingPlan.stakingLimit >= 0
                        ? stakingPlan.stakingLimit
                        : strings.unlimited}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      ) : (
        <DialogContent>
          <LoadingIndicator delay={0} />
        </DialogContent>
      )}

      <DialogActions>
        <Button onClick={onClose}>{strings.close}</Button>
      </DialogActions>
    </>
  );
}
