import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useConnection, useDomichainExplorerUrlSuffix } from './connection';
import Button from '@material-ui/core/Button';
import { confirmTransaction } from './utils';
import { EXPLORER_URL } from './env-variables';

/**
 * @returns {[(promise: Promise<unknown>, options: unknown) => Promise<void>, boolean]}
 */
export function useSendTransaction() {
  const connection = useConnection();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [sending, setSending] = useState(false);

  async function sendTransaction(
    signaturePromise,
    { onSuccess, onError } = {},
  ) {
    let id = enqueueSnackbar('Sending transaction...', {
      variant: 'info',
      persist: true,
    });
    setSending(true);
    try {
      let signature = await signaturePromise;
      closeSnackbar(id);
      enqueueSnackbar('Transaction confirmed', {
        variant: 'success',
        autoHideDuration: 5000,
        action: <ViewTransactionOnExplorerButton signature={signature} />,
      });
      // id = enqueueSnackbar('Confirming transaction...', {
      //   variant: 'success',
      //   autoHideDuration: 3000,
      //   action: <ViewTransactionOnExplorerButton signature={signature} />,
      // });
      // await confirmTransaction(connection, signature);
      // closeSnackbar(id);
      setSending(false);
      // enqueueSnackbar('Transaction confirmed', {
      //   variant: 'success',
      //   autoHideDuration: 15000,
      //   action: <ViewTransactionOnExplorerButton signature={signature} />,
      // });
      if (onSuccess) {
        onSuccess(signature);
      }
    } catch (e) {
      closeSnackbar(id);
      setSending(false);
      console.warn(e);
      enqueueSnackbar(e.message, { variant: 'error' });
      if (onError) {
        onError(e);
      }
    }
  }

  return [sendTransaction, sending];
}

function ViewTransactionOnExplorerButton({ signature }) {
  const urlSuffix = useDomichainExplorerUrlSuffix();
  return (
    <Button
      color="inherit"
      component="a"
      target="_blank"
      rel="noopener"
      href={`${EXPLORER_URL}/tx/${signature}` + urlSuffix}
    >
      View on Domiexplorer
    </Button>
  );
}

export function useCallAsync() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  return async function callAsync(
    promise,
    {
      progressMessage = 'Submitting...',
      successMessage = 'Success',
      onSuccess,
      onError,
    } = {},
  ) {
    let id = enqueueSnackbar(progressMessage, {
      variant: 'info',
      persist: true,
    });
    try {
      let result = await promise;
      closeSnackbar(id);
      if (successMessage) {
        enqueueSnackbar(successMessage, { variant: 'success' });
      }
      if (onSuccess) {
        onSuccess(result);
      }
    } catch (e) {
      console.warn(e);
      closeSnackbar(id);
      enqueueSnackbar(e.message, { variant: 'error' });
      if (onError) {
        onError(e);
      }
    }
  };
}
