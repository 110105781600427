export const NODE_URL = process.env.REACT_APP_NODE_URL;
export const EXPLORER_URL = process.env.REACT_APP_EXPLORER_URL;
export const STAKING_BACKEND_URL = process.env.REACT_APP_STAKING_URL;
export const AIRDROP_BACKEND_URL = process.env.REACT_APP_AIRDROP_URL;
export const USDT_BRIDGE_URL = process.env.REACT_APP_USDT_BRIDGE_URL;
export const USDT_BRIDGE_SERVICE_ADDRESS =
  process.env.REACT_APP_USDT_BRIDGE_SERVICE_ADDRESS;
export const BTC_BRIDGE_URL = process.env.REACT_APP_BTC_BRIDGE_URL;
export const BTC_BRIDGE_SERVICE_ADDRESS =
  process.env.REACT_APP_BTC_BRIDGE_SERVICE_ADDRESS;
