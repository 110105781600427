import { clusterApiUrl } from '@solana/web3.js';
import { MAINNET_URL } from '../utils/connection';

export const CLUSTERS = [
  {
    name: 'mainnet',
    apiUrl: 'https://mainnet.domichain.io',
    label: 'Domichain Mainnet',
    clusterSlug: 'mainnet',
  },
  {
    name: 'testnet',
    apiUrl: 'https://api.testnet.domichain.io',
    label: 'Domichain Testnet',
    clusterSlug: 'testnet',
  },
  {
    name: 'devnet',
    apiUrl: 'https://api.devnet.domichain.io',
    label: 'Domichain Devnet',
    clusterSlug: 'devnet',
  },
  {
    name: 'localnet',
    apiUrl: 'http://localhost:8899',
    label: 'Domichain (Local)',
    clusterSlug: 'localnet',
  },
];

export function clusterForEndpoint(endpoint) {
  return getClusters().find(({ apiUrl }) => apiUrl === endpoint);
}

const customClusterConfigKey = 'customClusterConfig';

export function addCustomCluster(name, apiUrl) {
  const stringifiedConfig = JSON.stringify({
    name: name,
    label: name,
    apiUrl: apiUrl,
    clusterSlug: null,
  });
  localStorage.setItem(customClusterConfigKey, stringifiedConfig);
}

export function customClusterExists() {
  return !!localStorage.getItem(customClusterConfigKey);
}

export function getClusters() {
  const stringifiedConfig = localStorage.getItem(customClusterConfigKey);
  const config = stringifiedConfig ? JSON.parse(stringifiedConfig) : null;
  return config ? [...CLUSTERS, config] : CLUSTERS;
}
