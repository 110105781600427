import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TextField from '@material-ui/core/TextField';
import { Close } from '@material-ui/icons';
import { Connection, PublicKey, TransactionSignature } from '@solana/web3.js';
import { useMutation, useQuery } from '@tanstack/react-query';
import QrcodeIcon from 'mdi-material-ui/Qrcode';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal, flushSync } from 'react-dom';
import strings from '../../../localization';
import {
  BTC_BRIDGE_SERVICE_ADDRESS,
  BTC_BRIDGE_URL,
} from '../../../utils/env-variables';
import { TokenAccount, useTokenAccounts } from '../../../utils/tokens/hooks';
import { BTCI_PROGRAM_ID } from '../../../utils/tokens/instructions';
import { isExtension, useDebounce } from '../../../utils/utils';
import { useBalanceInfo, useWallet, Wallet } from '../../../utils/wallet';
import DialogForm from '../../DialogForm';

const DOMI_ADDRESS_REGEX = /[1-9A-HJ-NP-Za-km-z]{32,44}/;
const BTC_ADDRESS_REGEX =
  /\b((bc|tb)(0([ac-hj-np-z02-9]{39}|[ac-hj-np-z02-9]{59})|1[ac-hj-np-z02-9]{8,87})|([13]|[mn2])[a-km-zA-HJ-NP-Z1-9]{25,39})\b/;

const BTCI_DECIMALS = 8;

const NETWORKS = {
  domichain: 'Domichain',
  bitcoin: 'Bitcoin',
};

export default function BtcSendDialog({
  open,
  onClose,
  publicKey,
  initialNetwork = 'domichain',
}: {
  open: boolean;
  onClose: () => void;
  publicKey?: PublicKey;
  initialNetwork: keyof typeof NETWORKS;
}) {
  const onSubmitRef = useRef<() => void>();
  const [selectedNetwork, setSelectedNetwork] = useState(initialNetwork);

  const balanceInfo = useBalanceInfo(publicKey);
  const { data: tokenAccounts } = useTokenAccounts(BTCI_PROGRAM_ID);

  const totalAmount =
    tokenAccounts && !publicKey
      ? tokenAccounts.reduce((acc, curr) => acc + getTokenAmount(curr), 0)
      : (balanceInfo?.amount as number) ?? 0;

  const form = useForm({
    selectedNetwork,
    setSelectedNetwork,
    tokenPubkey: publicKey,
    balanceAmount: totalAmount,
    decimals: BTCI_DECIMALS,
    feesEnabled: selectedNetwork === 'bitcoin',
  });

  const transferAmount = Math.round(
    parseFloat(form.transferAmountString) * 10 ** BTCI_DECIMALS,
  );

  return (
    <>
      <DialogForm
        open={open}
        onClose={onClose}
        onSubmit={() => onSubmitRef.current?.()}
        fullWidth
      >
        <DialogTitle>{strings.send} Bitcoin</DialogTitle>
        <Tabs
          value={selectedNetwork}
          variant="fullWidth"
          onChange={(_, value) => setSelectedNetwork(value)}
          textColor="primary"
          indicatorColor="primary"
        >
          {Object.entries(NETWORKS).map(([id, name]) => (
            <Tab key={id} label={name} value={id} />
          ))}
        </Tabs>
        {selectedNetwork === 'domichain' ? (
          <SendDplDialog
            form={form}
            transferAmount={transferAmount}
            publicKey={publicKey}
            onSubmitRef={onSubmitRef}
            onClose={onClose}
          />
        ) : (
          <SendBitcoinDialog
            form={form}
            transferAmount={transferAmount}
            publicKey={publicKey}
            onSubmitRef={onSubmitRef}
            onClose={onClose}
          />
        )}
      </DialogForm>
    </>
  );
}

function SendDplDialog({
  form,
  transferAmount,
  publicKey,
  onSubmitRef,
  onClose,
}: {
  form: ReturnType<typeof useForm>;
  transferAmount: number;
  publicKey?: PublicKey;
  onSubmitRef: React.MutableRefObject<(() => void) | undefined>;
  onClose: () => void;
}) {
  const wallet: Wallet = useWallet();

  const { mutate, isLoading } = usePerformTransferMutation(
    async (_i, account, transferAmount) => {
      const mintAddress = new PublicKey(
        (account.account.data.parsed as ParsedTokenAccountData).info.mint,
      );
      await wallet.transferToken(
        account.pubkey,
        new PublicKey(form.destinationAddress),
        transferAmount,
        mintAddress,
        BTCI_DECIMALS,
        null,
        true,
        BTCI_PROGRAM_ID,
      );
    },
  );

  const isDisabled =
    !form.validForm || !transferAmount || transferAmount <= 0 || isLoading;

  onSubmitRef.current = () =>
    mutate({ tokenPubkey: publicKey, transferAmount });

  return (
    <>
      <DialogContent>{form.fields}</DialogContent>
      <DialogActions
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div>
          <Button onClick={onClose}>{strings.cancel}</Button>
          <Button type="submit" color="primary" disabled={isDisabled}>
            {strings.send}
          </Button>
        </div>
      </DialogActions>
    </>
  );
}

function SendBitcoinDialog({
  form,
  transferAmount,
  publicKey,
  onSubmitRef,
  onClose,
}: {
  form: ReturnType<typeof useForm>;
  transferAmount: number;
  publicKey: PublicKey | undefined;
  onSubmitRef: any;
  onClose: () => void;
}) {
  const wallet: Wallet = useWallet();

  const { enqueueSnackbar } = useSnackbar();

  const { mutate, isLoading } = usePerformTransferMutation(
    async (i, account, transferAmount) => {
      console.debug('/sign_multisig_tx');

      const mintAddress = new PublicKey(
        (account.account.data.parsed as ParsedTokenAccountData).info.mint,
      );

      if (BTC_BRIDGE_SERVICE_ADDRESS === undefined) {
        throw Error('No bridge service address');
      }

      // Send BTCi to service address for burn
      const txSignature: TransactionSignature = await wallet.transferToken(
        account.pubkey,
        new PublicKey(BTC_BRIDGE_SERVICE_ADDRESS),
        transferAmount,
        mintAddress,
        BTCI_DECIMALS,
        null,
        true,
        BTCI_PROGRAM_ID,
      );

      // Confirm BTCi transfer
      const txResponse = await (
        wallet.connection as Connection
      ).confirmTransaction(txSignature, 'confirmed');
      if (txResponse.value.err !== null) {
        throw Error(
          `Failed to transfer BTCi: ${JSON.stringify(txResponse.value.err)}`,
        );
      }

      console.debug('/sign_multisig_tx BTCi transfer confirmed');

      const blockHeight = await (
        wallet.connection as Connection
      ).getBlockHeight();

      const requestBody = {
        mint_address: mintAddress.toBase58(),
        withdraw_address: form.destinationAddress,
        withdraw_amount: String(transferAmount),
        fee_rate: form.selectedFeeRate,
        vbytes: form.vbytes?.[i],
        domi_address: wallet.publicKey.toBase58(),
        block_height: blockHeight,
        btci_tx_signature: txSignature,
      };

      // Sign BTC transfer verification for backend
      const requestSignature = await wallet.createSignature(
        new TextEncoder().encode(JSON.stringify(requestBody)),
      );
      requestBody['signature'] = requestSignature;

      // Send BTC
      const response = await fetch(`${BTC_BRIDGE_URL}/sign_multisig_tx`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      console.debug('/sign_multisig_tx response.status', response.status);

      if (response.ok) {
        const json = await response.json();
        console.debug('/sign_multisig_tx json', json);
        if (json?.status !== 'ok') {
          throw new Error(strings.failedToSignAndSendAMultisigTransaction);
        }
        if (json.tx_link && json.tx_id) {
          enqueueSnackbar(
            <a href={json.tx_link} target="_blank" rel="noopener">
              Bitcoin Transaction Hash
            </a>,
            {
              variant: 'success',
              autoHideDuration: 10000,
            },
          );
        }
        onClose();
      } else {
        throw Error(strings.requestFailed);
      }
    },
  );

  const isDisabled =
    !form.validForm || !transferAmount || transferAmount <= 0 || isLoading;

  onSubmitRef.current = () =>
    mutate({ tokenPubkey: publicKey, transferAmount });

  return (
    <>
      <DialogContent>{form.fields}</DialogContent>
      <DialogActions
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div>
          <Button onClick={onClose}>{strings.cancel}</Button>
          <Button type="submit" color="primary" disabled={isDisabled}>
            {strings.send}
          </Button>
        </div>
      </DialogActions>
    </>
  );
}

function useFees(
  isEnabled: boolean,
  validFields: boolean,
  destinationAddress: string,
  transferAmountString: string,
  parsedAmount: number,
  tokenPubkey: PublicKey | undefined,
  decimals: number,
) {
  const { data: tokenAccounts } = useTokenAccounts(BTCI_PROGRAM_ID);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedFee, setSelectedFee] = React.useState<
    'fastest_fee' | 'half_hour_fee' | 'hour_fee'
  >('half_hour_fee');
  const handleSelectedFee = (event: any) => {
    setSelectedFee(event.target.value);
  };

  const [queryParsedAmount, ...queryParams] = useDebounce(
    [
      parsedAmount,
      tokenPubkey,
      tokenAccounts,
      destinationAddress,
      transferAmountString,
    ],
    1000,
  );
  const {
    data: estimateFeeData,
    isLoading,
    isFetched,
  } = useQuery({
    queryKey: ['btcEstimateFee', ...queryParams],
    enabled: isEnabled && validFields,
    refetchInterval: 15000, // 15 seconds auto refresh
    queryFn: async () => {
      console.debug('/estimate_fee');
      const vbytesArray: number[] = [];
      let recommendedFeeRates:
        | {
            fastest_fee: number;
            half_hour_fee: number;
            hour_fee: number;
            economy_fee: number;
            minimum_fee: number;
          }
        | undefined = undefined;
      try {
        const accounts = tokenPubkey
          ? tokenAccounts?.filter((account) =>
              account.pubkey.equals(tokenPubkey),
            )
          : tokenAccounts;

        if (!accounts) {
          throw new Error(strings.noAccountsFound);
        }

        const transferAmount = Math.round(
          parseFloat(transferAmountString) * 10 ** BTCI_DECIMALS,
        );

        for (const transferData of findSuitableAccountsForTransfer(
          accounts,
          transferAmount,
        )) {
          const { account, transferAmount: amount } = transferData;
          const mintAddress = new PublicKey(
            (account.account.data.parsed as ParsedTokenAccountData).info.mint,
          );
          const response = await fetch(`${BTC_BRIDGE_URL}/estimate_fee`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
            },
            body: JSON.stringify({
              mint_address: mintAddress.toBase58(),
              withdraw_address: destinationAddress,
              withdraw_amount: String(amount),
            }),
          });
          console.debug('/estimate_fee response.status', response.status);
          let data = await response.json();
          console.debug('/estimate_fee data', data);

          if (data.status !== 'ok') {
            console.error('/estimate_fee data', data);
            if (data.status === 'error') {
              throw Error(data.message);
            }
            throw Error(strings.requestFailed);
          }
          vbytesArray.push(data.vbytes);
          recommendedFeeRates = data.recommended_fee_rates;
        }
        if (recommendedFeeRates === undefined) {
          throw Error(strings.noFeeRates);
        }
      } catch (e) {
        console.error(e);
        enqueueSnackbar((e as any).message || e, { variant: 'error' });
      }

      return {
        vbytesArray,
        recommendedFeeRates,
      };
    },
  });

  const totalVbytes = estimateFeeData?.vbytesArray?.reduce(
    (partialSum, a) => partialSum + a,
    0,
  );
  const selectedFeeRate: number | undefined =
    estimateFeeData?.recommendedFeeRates?.[selectedFee];
  const validFeeRate = selectedFeeRate !== undefined;

  const expectedFee =
    totalVbytes && estimateFeeData?.recommendedFeeRates !== undefined
      ? balanceAmountToUserAmount(
          totalVbytes * estimateFeeData?.recommendedFeeRates[selectedFee],
          decimals,
        )
      : undefined;
  const receiveAmount =
    totalVbytes && estimateFeeData?.recommendedFeeRates !== undefined
      ? queryParsedAmount -
        totalVbytes * estimateFeeData?.recommendedFeeRates[selectedFee]
      : undefined;

  const receiveAmountUI =
    receiveAmount && balanceAmountToUserAmount(receiveAmount, decimals);
  const validReceiveAmount = receiveAmount !== undefined && receiveAmount > 0;

  const elements = isEnabled
    ? [
        !validFields && !isFetched ? null : isLoading ? (
          <Typography variant="body1" gutterBottom>
            {strings.loading}
          </Typography>
        ) : (
          estimateFeeData && (
            <>
              <Typography variant="body1" gutterBottom>
                {`${strings.expectedFee}: ${expectedFee} BTC`}
              </Typography>
              <Typography variant="body1" gutterBottom>
                {`${strings.youWillReceive}: ${receiveAmountUI} BTC`}
              </Typography>
              <FormControl component="fieldset">
                <FormLabel component="legend">{strings.selectTheFee}</FormLabel>
                <RadioGroup
                  aria-label="fee"
                  name="fee1"
                  value={selectedFee}
                  onChange={handleSelectedFee}
                >
                  <FormControlLabel
                    value="fastest_fee"
                    control={<Radio />}
                    label={`${strings.fastest} ${estimateFeeData.recommendedFeeRates?.['fastest_fee']} sat/vB ${strings.tenMinutes}`}
                  />
                  <FormControlLabel
                    value="half_hour_fee"
                    control={<Radio />}
                    label={`${strings.normal} ${estimateFeeData.recommendedFeeRates?.['half_hour_fee']} sat/vB ${strings.thirtyMinutes}`}
                  />
                  <FormControlLabel
                    value="hour_fee"
                    control={<Radio />}
                    label={`${strings.economy} ${estimateFeeData.recommendedFeeRates?.['hour_fee']} sat/vB ${strings.oneHour}`}
                  />
                </RadioGroup>
              </FormControl>
            </>
          )
        ),
      ]
    : [];
  return {
    elements,
    selectedFeeRate,
    vbytes: estimateFeeData?.vbytesArray,
    validFees: validFeeRate && validReceiveAmount,
  };
}

function useForm({
  selectedNetwork,
  setSelectedNetwork,
  tokenPubkey,
  balanceAmount,
  decimals,
  feesEnabled,
}: {
  selectedNetwork: keyof typeof NETWORKS;
  setSelectedNetwork: (network: keyof typeof NETWORKS) => void;
  tokenPubkey: PublicKey | undefined;
  balanceAmount: number;
  decimals: number;
  feesEnabled: boolean;
}) {
  const [scannerStatus, setScannerStatus] = useState<
    { denied: boolean; authorized: boolean } | undefined
  >();
  const [scannerIsVisible, setScannerIsVisible] = useState(false);

  const [destinationAddress, setDestinationAddress] = useState('');
  const [transferAmountString, setTransferAmountString] = useState('');

  const addressRegex =
    selectedNetwork === 'bitcoin' ? BTC_ADDRESS_REGEX : DOMI_ADDRESS_REGEX;

  const validAddress = addressRegex.test(destinationAddress);
  const showInvalidAddress = destinationAddress.length > 0 && !validAddress;

  const parsedAmount = Math.round(
    parseFloat(transferAmountString) * 10 ** decimals,
  );
  const validAmount = parsedAmount > 0 && parsedAmount <= balanceAmount;
  const showInvalidAmount = transferAmountString.length > 0 && !validAmount;

  const validFields = validAddress && validAmount;

  const {
    elements: feesElements,
    selectedFeeRate,
    vbytes,
    validFees,
  } = useFees(
    feesEnabled,
    validFields,
    destinationAddress,
    transferAmountString,
    parsedAmount,
    tokenPubkey,
    decimals,
  );

  const validForm = feesEnabled ? validFields && validFees : validFields;

  const presentQrScanner = useCallback(() => {
    window.QRScanner.prepare((_, status) => {
      setScannerStatus(status);
      setScannerIsVisible(true);
    });
  }, []);

  useEffect(() => {
    if (scannerIsVisible) {
      if (scannerStatus?.denied) {
        window.QRScanner.openSettings();
        return;
      }

      if (!scannerStatus?.authorized) {
        return;
      }

      const handleScan = (err, text) => {
        if (err) {
          setScannerIsVisible(false);
          return;
        }

        if (text) {
          if (BTC_ADDRESS_REGEX.test(text)) {
            flushSync(() => setSelectedNetwork('bitcoin'));
          } else if (DOMI_ADDRESS_REGEX.test(text)) {
            flushSync(() => setSelectedNetwork('domichain'));
          } else {
            return;
          }

          setDestinationAddress(text);
          setScannerIsVisible(false);
        }
      };

      const dialogElements = document.querySelectorAll('[role="presentation"]');
      const rootElement = document.getElementById('root');

      window.QRScanner.scan(handleScan);
      window.QRScanner.show(() => {
        dialogElements.forEach((el) => ((el as any).style.display = 'none'));
        (rootElement as any).style.display = 'none';
      });

      return () => {
        window.QRScanner.cancelScan();
        window.QRScanner.hide(() => {
          dialogElements.forEach((el) => ((el as any).style.display = ''));
          (rootElement as any).style.display = '';
        });
      };
    }
  }, [scannerStatus, scannerIsVisible, addressRegex]);

  const disabledEnterOnKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const fields = (
    <>
      <TextField
        label={strings.recipientAddress}
        fullWidth
        variant="outlined"
        margin="normal"
        value={destinationAddress}
        onChange={(e) => setDestinationAddress(e.target.value.trim())}
        // helperText={addressHelperText}
        id={showInvalidAddress ? 'outlined-error-helper-text' : undefined}
        error={showInvalidAddress}
        InputProps={
          isExtension
            ? {
                inputProps: {
                  onKeyDown: disabledEnterOnKeyDown,
                },
              }
            : {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton color="inherit" onClick={presentQrScanner}>
                      <QrcodeIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                inputProps: {
                  onKeyDown: disabledEnterOnKeyDown,
                },
              }
        }
      />
      <TextField
        label={strings.amount}
        fullWidth
        variant="outlined"
        margin="normal"
        id={showInvalidAmount ? 'outlined-error-helper-text' : undefined}
        error={showInvalidAmount}
        type="number"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Button
                onClick={() =>
                  setTransferAmountString(
                    balanceAmountToUserAmount(balanceAmount, decimals),
                  )
                }
              >
                {strings.max}
              </Button>
              <Typography color="primary">BTC</Typography>
            </InputAdornment>
          ),
          inputProps: {
            step: Math.pow(10, -decimals),
            onKeyDown: disabledEnterOnKeyDown,
          },
        }}
        value={transferAmountString}
        onChange={(e) => setTransferAmountString(e.target.value.trim())}
        helperText={
          <span
            onClick={() =>
              setTransferAmountString(
                balanceAmountToUserAmount(balanceAmount, decimals),
              )
            }
          >
            {strings.max}: {balanceAmountToUserAmount(balanceAmount, decimals)}
          </span>
        }
      />
      {feesElements}
      {scannerIsVisible &&
        createPortal(
          <div
            style={{
              margin: 8,
              paddingTop: 'env(safe-area-inset-top)',
            }}
          >
            <IconButton
              color="inherit"
              onClick={() => setScannerIsVisible(false)}
            >
              <Close />
            </IconButton>
          </div>,
          document.body,
        )}
    </>
  );

  return {
    fields,
    destinationAddress,
    transferAmountString,
    setDestinationAddress,
    selectedFeeRate,
    vbytes,
    validForm,
  };
}

function usePerformTransferMutation(
  mutationFn: (
    index: number,
    account: TokenAccount,
    transferAmount: number,
  ) => Promise<void>,
) {
  const { data: tokenAccounts } = useTokenAccounts(BTCI_PROGRAM_ID);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: async ({
      tokenPubkey,
      transferAmount,
    }: {
      tokenPubkey: PublicKey | undefined;
      transferAmount: number;
    }) => {
      const id = enqueueSnackbar(strings.sendingTransaction, {
        variant: 'info',
        persist: true,
      });

      try {
        const accounts = tokenPubkey
          ? tokenAccounts?.filter((account) =>
              account.pubkey.equals(tokenPubkey),
            )
          : tokenAccounts;

        if (!accounts) {
          throw new Error(strings.noAccountsFound);
        }

        let i = 0;
        for (const transferData of findSuitableAccountsForTransfer(
          accounts,
          transferAmount,
        )) {
          const { account, transferAmount: amount } = transferData;
          await mutationFn(i, account, amount);
          i += 1;
        }

        closeSnackbar(id);
        console.debug('mutation success');
        enqueueSnackbar(strings.transactionConfirmed, {
          variant: 'success',
          autoHideDuration: 5000,
          // action: <ViewTransactionOnExplorerButton signature={signature} />,
        });
      } catch (e) {
        closeSnackbar(id);
        console.debug('mutation error');
        enqueueSnackbar((e as any).message, { variant: 'error' });
      }
    },
  });
}

function findSuitableAccountsForTransfer(
  accounts: TokenAccount[],
  transferAmount: number,
) {
  const result: { account: TokenAccount; transferAmount: number }[] = [];
  const sortedAccounts = accounts
    .slice()
    .sort((a, b) => getTokenAmount(b) - getTokenAmount(a));

  let remainingTransferAmount: number = transferAmount;

  for (const account of sortedAccounts) {
    if (remainingTransferAmount <= 0) {
      break;
    }
    const maxTransfer = Math.min(
      getTokenAmount(account),
      remainingTransferAmount,
    );
    result.push({ account, transferAmount: maxTransfer });
    remainingTransferAmount -= maxTransfer;
  }

  if (remainingTransferAmount > 0) {
    throw new Error(strings.insufficientFunds);
  }
  return result;
}

function balanceAmountToUserAmount(
  balanceAmount: number,
  decimals: number,
): string {
  const a = balanceAmount / Math.pow(10, decimals);
  return a.toFixed(decimals);
}

type ParsedTokenAccountData = {
  info: {
    isNative: boolean;
    mint: string;
    owner: string;
    state: string; // "initialized"
    tokenAmount: {
      amount: string;
      decimals: number;
      uiAmount: number;
      uiAmountString: string;
    };
  };
};

function getTokenAmount(tokenAccount: TokenAccount): number {
  return parseInt(
    (tokenAccount.account.data.parsed as ParsedTokenAccountData).info
      .tokenAmount.amount,
  );
}
