import { DialogContentText } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { Keypair } from '@solana/web3.js';
import React, { useMemo, useState } from 'react';
import strings from '../localization';
import { useSendTransaction } from '../utils/notifications';
import { createAndInitializeMint } from '../utils/tokens';
import { useUpdateTokenName } from '../utils/tokens/names';
import { abbreviateAddress } from '../utils/utils';
import { useWallet } from '../utils/wallet';
import DialogForm from './DialogForm';
import { getMetadataBySymbol } from '../utils/tokens/metadata';
import { useConnection } from '../utils/connection';

export default function MintTokenDialog({ open, onClose }) {
  const wallet = useWallet();
  const connection = useConnection();

  const updateTokenName = useUpdateTokenName();
  const [sendTransaction, sending] = useSendTransaction();

  const tokenAccount = useMemo(() => new Keypair(), []);
  const defaultTokenName = `Test Token ${abbreviateAddress(
    tokenAccount.publicKey,
  )}`;
  const defaultTokenSymbol = `TEST${tokenAccount.publicKey
    .toBase58()
    .slice(0, 2)}`;

  const [tokenName, setTokenName] = useState(defaultTokenName);
  const [tokenSymbol, setTokenSymbol] = useState(defaultTokenSymbol);
  const [tokenDecimals, setTokenDecimals] = useState(2);
  const [mintAmount, setMintAmount] = useState(1000);

  const mintNewToken = async () => {
    const decimals = parseFloat(tokenDecimals);
    if (!decimals || decimals <= 0) {
      throw new Error('Invalid decimal places');
    }

    const amount = Math.round(parseFloat(mintAmount) * 10 ** decimals);
    if (!amount || amount <= 0) {
      throw new Error('Invalid amount');
    }

    const existingTokens = await getMetadataBySymbol(connection, tokenSymbol);
    if (existingTokens.length > 0) {
      throw new Error(
        `Token symbol "${tokenSymbol}" already exists, choose a different one`,
      );
    }

    return await createAndInitializeMint({
      connection,
      owner: wallet,
      mint: tokenAccount,
      name: tokenName,
      symbol: tokenSymbol,
      amount: amount,
      decimals: decimals,
      initialAccount: new Keypair(),
    });
  };

  const handleOnPressMint = () => {
    sendTransaction(mintNewToken(), {
      onSuccess: () => {
        updateTokenName(
          tokenAccount.publicKey,
          tokenName || defaultTokenName,
          tokenSymbol || defaultTokenSymbol,
          true,
        );
        onClose();
      },
      onError: (error) => {
        console.log('Failed to mint a token:', error);
        onClose();
      },
    });
  };

  return (
    <DialogForm open={open} onClose={onClose} onSubmit={onClose} fullWidth>
      <DialogTitle>{strings.mintNewToken}</DialogTitle>
      <DialogContent>
        <DialogContentText>{strings.mintNewTokenDescription}</DialogContentText>
        <TextField
          label={strings.tokenName}
          fullWidth
          variant="outlined"
          margin="normal"
          value={tokenName}
          onChange={(e) => setTokenName(e.target.value)}
        />
        <TextField
          label={strings.tokenSymbol}
          fullWidth
          variant="outlined"
          margin="normal"
          value={tokenSymbol}
          onChange={(e) => setTokenSymbol(e.target.value)}
        />
        <TextField
          label={strings.tokenDecimals}
          fullWidth
          variant="outlined"
          margin="normal"
          type="number"
          value={tokenDecimals}
          onChange={(e) => setTokenDecimals(e.target.value)}
        />
        <TextField
          label={strings.amount}
          fullWidth
          variant="outlined"
          margin="normal"
          type="number"
          value={mintAmount}
          onChange={(e) => setMintAmount(e.target.value)}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{strings.close}</Button>
        <Button
          type="submit"
          color="primary"
          onClick={handleOnPressMint}
          disabled={sending}
        >
          Mint
        </Button>
      </DialogActions>
    </DialogForm>
  );
}
