import React, { useState } from 'react';
import DialogForm from '../DialogForm';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import strings from '../../localization';
import { STAKING_BACKEND_URL } from '../../utils/env-variables';
import useLocalStorageState from 'use-local-storage-state';
import { IconButton } from '@material-ui/core';
import { Settings } from '@material-ui/icons';
import { useIsExtensionWidth } from '../../utils/utils';

export default function StakingSettingsDialog() {
  const [stakingBackendUrl, setStackingBackendUrl] = useLocalStorageState(
    'stackingBackendUrl',
    { defaultValue: STAKING_BACKEND_URL },
  );

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(stakingBackendUrl);

  const isExtensionWidth = useIsExtensionWidth();
  const iconSize = isExtensionWidth ? 'small' : 'medium';

  return (
    <>
      <IconButton size={iconSize} onClick={() => setOpen(true)}>
        <Settings />
      </IconButton>

      <DialogForm
        open={open}
        onClose={() => setOpen(false)}
        onSubmit={() => setStackingBackendUrl(value)}
        fullWidth
      >
        <DialogTitle>{strings.settings}</DialogTitle>
        <DialogContent>
          <TextField
            label={strings.stakingUrl}
            fullWidth
            variant="outlined"
            margin="normal"
            type="url"
            value={value}
            onChange={(e) => setValue(e.target.value.trim())}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>{strings.close}</Button>
          <Button type="submit" color="primary">
            {strings.save}
          </Button>
        </DialogActions>
      </DialogForm>
    </>
  );
}
