import * as beet from '@metaplex-foundation/beet';
import * as beetSolana from '@metaplex-foundation/beet-solana';
import * as web3 from '@solana/web3.js';
import bs58 from 'bs58';

export const TOKEN_METADATA_PROGRAM_ID = new web3.PublicKey(
  'MetaXKaVt8cn9dGYns81au23cqBYUH4DU4WpC8tAbhQ',
);

export const DATA_OFFSET =
  1 + // key
  32 + // update auth
  32; // mint

export const DATA_SYMBOL_OFFSET =
  DATA_OFFSET +
  4 + // name string length
  32 + // max name length
  4; // symbol string length

const creatorBeet = new beet.BeetArgsStruct(
  [
    ['address', beetSolana.publicKey],
    ['verified', beet.bool],
    ['share', beet.u8],
  ],
  'Creator',
);

const collectionBeet = new beet.BeetArgsStruct(
  [
    ['verified', beet.bool],
    ['key', beetSolana.publicKey],
  ],
  'Collection',
);

const useMethodBeet = beet.fixedScalarEnum({
  0: 'Burn',
  1: 'Multiple',
  2: 'Single',
  Burn: 0,
  Multiple: 1,
  Single: 2,
});

const usesBeet = new beet.BeetArgsStruct(
  [
    ['useMethod', useMethodBeet],
    ['remaining', beet.u64],
    ['total', beet.u64],
  ],
  'Uses',
);

const dataV2Beet = new beet.FixableBeetArgsStruct(
  [
    ['name', beet.utf8String],
    ['symbol', beet.utf8String],
    ['uri', beet.utf8String],
    ['sellerFeeBasisPoints', beet.u16],
    ['creators', beet.coption(beet.array(creatorBeet))],
    ['collection', beet.coption(collectionBeet)],
    ['uses', beet.coption(usesBeet)],
  ],
  'DataV2',
);

const collectionDetailsBeet = beet.dataEnum([
  [
    'V1',
    new beet.BeetArgsStruct(
      [['size', beet.u64]],
      'CollectionDetailsRecord["V1"]',
    ),
  ],
]);

const createMetadataAccountArgsV3Beet = new beet.FixableBeetArgsStruct(
  [
    ['data', dataV2Beet],
    ['isMutable', beet.bool],
    ['collectionDetails', beet.coption(collectionDetailsBeet)],
  ],
  'CreateMetadataAccountArgsV3',
);

/**
 * @category Instructions
 * @category CreateMetadataAccountV3
 * @category generated
 */
export const CreateMetadataAccountV3Struct = new beet.FixableBeetArgsStruct(
  [
    ['instructionDiscriminator', beet.u8],
    ['createMetadataAccountArgsV3', createMetadataAccountArgsV3Beet],
  ],
  'CreateMetadataAccountV3InstructionArgs',
);

export const createMetadataAccountV3InstructionDiscriminator = 33;

/**
 * Creates a _CreateMetadataAccountV3_ instruction.
 *
 * Optional accounts that are not provided will be omitted from the accounts
 * array passed with the instruction.
 * An optional account that is set cannot follow an optional account that is unset.
 * Otherwise an Error is raised.
 *
 * @param accounts that will be accessed while the instruction is processed
 * @param args to provide as instruction data to the program
 *
 * @category Instructions
 * @category CreateMetadataAccountV3
 * @category generated
 */
export function createCreateMetadataAccountV3Instruction(
  accounts,
  args,
  programId,
) {
  const [data] = CreateMetadataAccountV3Struct.serialize({
    instructionDiscriminator: createMetadataAccountV3InstructionDiscriminator,
    ...args,
  });
  const keys = [
    {
      pubkey: accounts.metadata,
      isWritable: true,
      isSigner: false,
    },
    {
      pubkey: accounts.mint,
      isWritable: false,
      isSigner: false,
    },
    {
      pubkey: accounts.mintAuthority,
      isWritable: false,
      isSigner: true,
    },
    {
      pubkey: accounts.payer,
      isWritable: true,
      isSigner: true,
    },
    {
      pubkey: accounts.updateAuthority,
      isWritable: false,
      isSigner: false,
    },
    {
      pubkey: accounts.systemProgram ?? web3.SystemProgram.programId,
      isWritable: false,
      isSigner: false,
    },
  ];

  if (accounts.rent != null) {
    keys.push({
      pubkey: accounts.rent,
      isWritable: false,
      isSigner: false,
    });
  }

  const ix = new web3.TransactionInstruction({
    programId,
    keys,
    data,
  });
  return ix;
}

export function getMetadataPDA(mint) {
  return web3.PublicKey.findProgramAddressSync(
    [
      Buffer.from('metadata', 'utf-8'),
      TOKEN_METADATA_PROGRAM_ID.toBuffer(),
      mint.toBuffer(),
    ],
    TOKEN_METADATA_PROGRAM_ID,
  )[0];
}

export async function getMetadataByMint(connection, publicKey) {
  const accountInfo = await connection.getAccountInfo(
    getMetadataPDA(publicKey),
  );
  try {
    return accountInfo.data
      ? dataV2Beet.deserialize(accountInfo.data, DATA_OFFSET)[0]
      : null;
  } catch {
    return null;
  }
}

export async function getMetadataBySymbol(connection, symbol) {
  const accounts = await connection.getProgramAccounts(
    TOKEN_METADATA_PROGRAM_ID,
    {
      commitment: 'finalized',
      filters: [
        {
          memcmp: {
            offset: DATA_SYMBOL_OFFSET,
            bytes: bs58.encode(new TextEncoder().encode(symbol)),
          },
        },
      ],
    },
  );
  return accounts.map(
    ({ account }) => dataV2Beet.deserialize(account.data, DATA_OFFSET)[0],
  );
}
