import { DialogContentText, Link, Tab, Tabs } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import strings from '../../../localization';
import { useWallet } from '../../../utils/wallet';
import CopyableDisplay from '../../CopyableDisplay';
import DialogForm from '../../DialogForm';
import LoadingIndicator from '../../LoadingIndicator';
import { USDT_BRIDGE_URL } from '../../../utils/env-variables';
import { shortenAddress } from '../../../utils/utils';

const NETWORKS = {
  domichain: 'Domichain',
  bsc: 'Binance Smart Chain',
};

export default function UsdtDepositDialog({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const wallet = useWallet();
  const [selectedNetwork, setSelectedNetwork] = useState('bsc');

  const { data: depositInfo } = useQuery({
    queryKey: ['usdtDepositInfo', wallet.publicKey.toBase58()],
    enabled: open && selectedNetwork === 'bsc',
    queryFn: async () => {
      const response = await fetch(`${USDT_BRIDGE_URL}/get_deposit_address`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          domi_address: wallet.publicKey.toBase58(),
        }),
      });

      if (response.ok) {
        const responseJson = await response.json();
        if (responseJson.status === 'ok') {
          return {
            usdtContractAddress: responseJson.usdt_contract_address,
            depositAddress: responseJson.deposit_address,
          };
        }
      }
    },
  });

  return (
    <DialogForm
      open={open}
      onClose={onClose}
      onSubmit={undefined}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>{strings.deposit} USDT</DialogTitle>
      {/* <Tabs
        value={selectedNetwork}
        variant="fullWidth"
        onChange={(_, value) => setSelectedNetwork(value)}
        textColor="primary"
        indicatorColor="primary"
      >
        {Object.entries(NETWORKS).map(([id, name]) => (
          <Tab key={id} label={name} value={id} />
        ))}
      </Tabs> */}
      <DialogContent>
        {depositInfo ? (
          <>
            <CopyableDisplay
              value={depositInfo.depositAddress}
              label={strings.depositAddress}
              autoFocus={false}
              qrCode
            />
            <DialogContentText variant="body2">
              {strings.usdtContractAddress}{' '}
              <Link
                href={`https://bscscan.com/token/${depositInfo.usdtContractAddress}`}
                target="_blank"
                rel="noopener"
              >
                {shortenAddress(depositInfo.usdtContractAddress, 6)}
              </Link>
            </DialogContentText>
            <DialogContentText>
              {strings.usdtDepositNotice.replace(
                '%s',
                NETWORKS[selectedNetwork],
              )}
            </DialogContentText>
          </>
        ) : (
          <LoadingIndicator delay={0} />
        )}
        {/* {selectedNetwork === 'domichain' ? (
          <CopyableDisplay
            value={wallet.publicKey.toBase58()}
            label={strings.depositAddress}
            autoFocus={false}
            qrCode
          />
        ) : isFetched ? (
          <CopyableDisplay
            value={usdtDepositAddress}
            label={strings.depositAddress}
            autoFocus={false}
            qrCode
          />
        ) : (
          <LoadingIndicator delay={0} />
        )} */}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{strings.close}</Button>
      </DialogActions>
    </DialogForm>
  );
}
