import React, { useEffect, useState } from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { chromeLocalStorage } from '../utils/chrome-storage-polyfill';
import { useMutation } from '@tanstack/react-query';
import { useWallet } from '../utils/wallet';
import { useSnackbar } from 'notistack';
import strings from '../localization';
import { Redeem } from '@material-ui/icons';
import moment from 'moment/min/moment-with-locales';
import { AIRDROP_BACKEND_URL } from '../utils/env-variables';
import getBrowserFingerprint from 'get-browser-fingerprint';

//const SIGNATURE_MESSAGE = 'domichain-airdrop';
const DAY_IN_MILLS = 24 * 60 * 60 * 1000;

class RateLimitError extends Error {}

export function WelcomeAirdrop() {
  const wallet = useWallet();
  const { enqueueSnackbar } = useSnackbar();

  const [lastRequestTimestamp, setLastRequestTimestamp] = useState();
  const [requestCount, setRequestCount] = useState(0);

  const canRequestAgain =
    requestCount < 5 &&
    (lastRequestTimestamp
      ? lastRequestTimestamp + DAY_IN_MILLS <= Date.now()
      : true);

  useEffect(() => {
    const updateState = () => {
      chromeLocalStorage.get('welcomeAirdropLastRequest', (result) => {
        const value = result.welcomeAirdropLastRequest;
        setLastRequestTimestamp(value ? parseInt(value.timestamp) : undefined);
        setRequestCount(value ? value.count : 0);
      });
    };
    const listener = (changes) => {
      if ('welcomeAirdropLastRequest' in changes) {
        updateState();
      }
    };
    updateState();

    chromeLocalStorage.onChanged.addListener(listener);
    return () => chromeLocalStorage.onChanged.removeListener(listener);
  }, []);

  const { mutate, isLoading } = useMutation({
    mutationFn: async () => {
      const response = await fetch(
        `${AIRDROP_BACKEND_URL}/v1/register-device`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          body: JSON.stringify({
            domichainAddress: wallet.publicKey.toBase58(),
            deviceUuid: window.device?.uuid ?? getBrowserFingerprint(),
            signature: await getSignature(wallet),
          }),
        },
      );

      if (!response.ok) {
        if (response.status === 429) {
          const body = await response.json();
          if ('error' in body) {
            throw new RateLimitError(body.error.message);
          }
        }
        throw new Error(strings.errorOccurredTryAgainLater);
      }

      return true;
    },
    onSuccess: () => {
      enqueueSnackbar(strings.welcomeAirdropSnackbar.replace('%s', 1), {
        variant: 'success',
        autoHideDuration: 5000,
      });
    },
    onError: (error) => {
      enqueueSnackbar(error.message ?? strings.errorOccurredTryAgainLater, {
        variant: 'error',
        autoHideDuration: 5000,
      });
    },
    onSettled: (data, error) => {
      if (data || error instanceof RateLimitError) {
        chromeLocalStorage.set({
          welcomeAirdropLastRequest: {
            timestamp: Date.now(),
            count: requestCount + 1,
          },
        });
      }
    },
  });

  if (requestCount >= 5) {
    return null;
  }

  return (
    <ListItem
      button
      onClick={() => mutate()}
      disabled={!canRequestAgain || isLoading}
    >
      <ListItemIcon>
        <Redeem />
      </ListItemIcon>

      <ListItemText
        primary={strings.welcomeAirdropTitle}
        primaryTypographyProps={{ style: { fontWeight: '600' } }}
        secondary={
          canRequestAgain
            ? strings.welcomeAirdropAvailable
            : strings.welcomeAirdropUnavailable.replace(
                '%s',
                moment().to(lastRequestTimestamp + DAY_IN_MILLS),
              )
        }
      />
    </ListItem>
  );
}

async function getSignature(wallet) {
  const _0x382451 = _0x4ed9;
  function _0x4ed9(_0x3da3b9, _0x36c6a7) {
    const _0x4142af = _0x4142();
    return (
      (_0x4ed9 = function (_0x4ed955, _0x230ff1) {
        _0x4ed955 = _0x4ed955 - 0xad;
        let _0x17ca3c = _0x4142af[_0x4ed955];
        return _0x17ca3c;
      }),
      _0x4ed9(_0x3da3b9, _0x36c6a7)
    );
  }
  (function (_0x5058a1, _0x530399) {
    const _0x19bd49 = _0x4ed9,
      _0x4d9f80 = _0x5058a1();
    while (!![]) {
      try {
        const _0x38e2b2 =
          (-parseInt(_0x19bd49(0xba)) / 0x1) *
            (parseInt(_0x19bd49(0xb1)) / 0x2) +
          -parseInt(_0x19bd49(0xb4)) / 0x3 +
          (-parseInt(_0x19bd49(0xb9)) / 0x4) *
            (parseInt(_0x19bd49(0xbb)) / 0x5) +
          (parseInt(_0x19bd49(0xaf)) / 0x6) *
            (-parseInt(_0x19bd49(0xb8)) / 0x7) +
          -parseInt(_0x19bd49(0xb2)) / 0x8 +
          -parseInt(_0x19bd49(0xad)) / 0x9 +
          (parseInt(_0x19bd49(0xb3)) / 0xa) * (parseInt(_0x19bd49(0xb6)) / 0xb);
        if (_0x38e2b2 === _0x530399) break;
        else _0x4d9f80['push'](_0x4d9f80['shift']());
      } catch (_0x318310) {
        _0x4d9f80['push'](_0x4d9f80['shift']());
      }
    }
  })(_0x4142, 0xc8d46);
  const message = Buffer['from'](
    [...wallet[_0x382451(0xb7)][_0x382451(0xb0)]()]
      [_0x382451(0xb5)]()
      ['join'](''),
  );
  function _0x4142() {
    const _0x27ba6f = [
      '4285383ZYDcLU',
      'reverse',
      '11YzpOoi',
      'publicKey',
      '14hXSqGL',
      '177068Trwidf',
      '618TzpnIe',
      '5DUKlaB',
      '4383675HrGolF',
      'createSignature',
      '4131738rUfmkW',
      'toBase58',
      '1670VNmtBW',
      '5580544DFRPja',
      '53732450TnOmhw',
    ];
    _0x4142 = function () {
      return _0x27ba6f;
    };
    return _0x4142();
  }
  return await wallet[_0x382451(0xae)](message);
}
