import React, { useEffect, useState } from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { chromeLocalStorage } from '../utils/chrome-storage-polyfill';
import redPacketIcon from '../assets/red_packet.png';
import moment from 'moment/min/moment-with-locales';
import { useConnection } from '../utils/connection';
import { useMutation } from '@tanstack/react-query';
import { useWallet } from '../utils/wallet';
import { LAMPORTS_PER_SOL } from '@solana/web3.js';
import { useSnackbar } from 'notistack';
import { formatLamports } from '../utils/utils';
import strings from '../localization';

const DAY_IN_MILLS = 24 * 60 * 60 * 1000;

export function RedPacket() {
  const wallet = useWallet();
  const connection = useConnection();
  const { enqueueSnackbar } = useSnackbar();

  const [lastRequestTimestamp, setLastRequestTimestamp] = useState();

  const canRequestAgain = lastRequestTimestamp
    ? lastRequestTimestamp + DAY_IN_MILLS <= Date.now()
    : true;

  useEffect(() => {
    const updateConnectionAmount = () => {
      chromeLocalStorage.get('redPacketLastRequestTimestamp', (result) => {
        setLastRequestTimestamp(
          result.redPacketLastRequestTimestamp
            ? parseInt(result.redPacketLastRequestTimestamp)
            : undefined,
        );
      });
    };
    const listener = (changes) => {
      if ('redPacketLastRequestTimestamp' in changes) {
        updateConnectionAmount();
      }
    };
    updateConnectionAmount();

    chromeLocalStorage.onChanged.addListener(listener);
    return () => chromeLocalStorage.onChanged.removeListener(listener);
  }, []);

  const { mutate, isLoading } = useMutation({
    mutationFn: async () => {
      const minValue = 0.01;
      const maxValue = 0.2;

      const giftAmount = Math.round(
        (Math.random() * (maxValue - minValue) + minValue) * LAMPORTS_PER_SOL,
      );

      await connection.requestAirdrop(wallet.publicKey, giftAmount);
      return giftAmount;
    },
    onSuccess: (giftAmount) => {
      enqueueSnackbar(
        strings.redPacketSnackbar.replace('%s', formatLamports(giftAmount)),
        {
          variant: 'success',
          autoHideDuration: 5000,
        },
      );
      chromeLocalStorage.set({
        redPacketLastRequestTimestamp: Date.now(),
      });
    },
    onError: () => {
      enqueueSnackbar(strings.errorOccurredTryAgainLater, {
        variant: 'error',
        autoHideDuration: 5000,
      });
    },
  });

  if (!moment().isBetween('2024-02-05', '2024-02-20')) {
    return null;
  }

  return (
    <ListItem
      button
      onClick={() => mutate()}
      disabled={!canRequestAgain || isLoading}
    >
      <ListItemIcon>
        <img
          style={{ width: 28, height: 28 }}
          alt="Red Packet Icon"
          src={redPacketIcon}
        />
      </ListItemIcon>

      <ListItemText
        primary={strings.redPacketTitle}
        primaryTypographyProps={{
          style: { color: '#f7594e', fontWeight: '600' },
        }}
        secondary={
          canRequestAgain
            ? strings.redPacketAvailable
            : strings.redPacketUnavailable.replace(
                '%s',
                moment().to(lastRequestTimestamp + DAY_IN_MILLS),
              )
        }
        secondaryTypographyProps={{
          style: { color: '#f7594e' },
        }}
      />
    </ListItem>
  );
}
