import { DialogContentText, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { Box, InputAdornment } from '@mui/material';
import {
  Authorized,
  LAMPORTS_PER_SOL,
  PublicKey,
  StakeProgram,
} from '@solana/web3.js';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import strings from '../../../localization';
import { useConnection } from '../../../utils/connection';
import { useSendTransaction } from '../../../utils/notifications';
import { signAndSendTransaction } from '../../../utils/tokens';
import { formatLamports } from '../../../utils/utils';
import { useBalanceInfo, useWallet } from '../../../utils/wallet';
import StakingSettingsDialog from '../StakingSettingsDialog';

export function DelegateStake({
  votePubkey,
  stakingBackendUrl,
  stakingLimit,
  onClose,
}: {
  votePubkey: string;
  stakingBackendUrl: string;
  stakingLimit: number;
  onClose: () => void;
}) {
  const wallet = useWallet();
  const connection = useConnection();
  const balanceInfo = useBalanceInfo(wallet.publicKey);
  const queryClient = useQueryClient();

  const [sendTransaction, sending] = useSendTransaction();

  const [tokenAmount, setTokenAmount] = useState<string>('');

  const delegateStake = async () => {
    const stakeAccount = await wallet.getStakeAccount();
    const stakeMinimumDelegation = await connection.getStakeMinimumDelegation();
    const lamports = Math.round(parseFloat(tokenAmount) * LAMPORTS_PER_SOL);

    if (!lamports || lamports < stakeMinimumDelegation.value) {
      throw new Error(
        `Stake amount should be more than ${formatLamports(
          stakeMinimumDelegation.value,
        )} DOMI`,
      );
    }

    if (stakingLimit > 0 && lamports > stakingLimit * LAMPORTS_PER_SOL) {
      throw new Error(`Stake amount should not exceed ${stakingLimit} DOMI`);
    }

    {
      const transaction = StakeProgram.createAccountWithSeed({
        fromPubkey: wallet.publicKey,
        basePubkey: wallet.publicKey,
        stakePubkey: stakeAccount,
        seed: 'stake',
        authorized: new Authorized(wallet.publicKey, wallet.publicKey),
        lamports: lamports,
      });

      const { blockhash, lastValidBlockHeight } =
        await connection.getLatestBlockhash();

      transaction.feePayer = wallet.publicKey;
      transaction.recentBlockhash = blockhash;
      transaction.lastValidBlockHeight = lastValidBlockHeight;

      const signature = await signAndSendTransaction(
        connection,
        transaction,
        wallet,
        [],
      );

      const result = await connection.confirmTransaction(
        { signature, blockhash, lastValidBlockHeight },
        'finalized',
      );

      if (result.value.err) {
        throw new Error(
          `Failed to confirm create account transaction: ${result.value.err}`,
        );
      }
    }

    {
      const transaction = StakeProgram.delegate({
        stakePubkey: stakeAccount,
        authorizedPubkey: wallet.publicKey,
        votePubkey: new PublicKey(votePubkey),
      });

      const { blockhash, lastValidBlockHeight } =
        await connection.getLatestBlockhash();

      transaction.feePayer = wallet.publicKey;
      transaction.recentBlockhash = blockhash;
      transaction.lastValidBlockHeight = lastValidBlockHeight;

      return await signAndSendTransaction(connection, transaction, wallet, []);
    }
  };

  const handleOnPress = async () => {
    sendTransaction(delegateStake(), {
      onSuccess: async () => {
        try {
          const stakeAccount = await wallet.getStakeAccount();
          const stakeAccountInfo = await connection.getStakeActivation(
            stakeAccount,
          );

          const stakeAmount =
            stakeAccountInfo.active + stakeAccountInfo.inactive;

          localStorage.setItem(
            `initialStake_${stakeAccount.toBase58()}`,
            stakeAmount.toString(),
          );

          try {
            const message = Buffer.from('domichain-staking');
            const signature = await wallet.createSignature(message);

            await fetch(
              `${stakingBackendUrl}/v1/stakes/${stakeAccount.toBase58()}`,
              {
                method: 'POST',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  ownerAddress: wallet.publicKey.toBase58(),
                  stakeAmount,
                  signature,
                }),
              },
            );
          } catch (error) {
            console.warn('Failed to save initial stake in the backend', error);
          }
        } finally {
          queryClient.refetchQueries({
            queryKey: ['stakeAccountInfo'],
          });
        }
      },
    });
  };

  return (
    <>
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>{strings.startStaking}</Box>
          <Box>
            <StakingSettingsDialog />
          </Box>
        </Box>
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          {strings.stakingDelegateDescription}
        </DialogContentText>

        <TextField
          label={strings.amount}
          fullWidth
          autoFocus
          variant="outlined"
          margin="normal"
          type="number"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button
                  onClick={() =>
                    setTokenAmount(
                      balanceAmountToUserAmount(
                        Math.max(0, balanceInfo?.amount - 1 * LAMPORTS_PER_SOL),
                        balanceInfo?.decimals,
                      ),
                    )
                  }
                >
                  {strings.max}
                </Button>
                <Typography color="primary">
                  {balanceInfo?.tokenSymbol}
                </Typography>
              </InputAdornment>
            ),
            inputProps: {
              step: Math.pow(10, -balanceInfo?.decimals),
            },
          }}
          value={tokenAmount}
          onChange={(e) => setTokenAmount(e.target.value.trim())}
          helperText={
            <span
              onClick={() =>
                setTokenAmount(
                  balanceAmountToUserAmount(
                    balanceInfo?.amount,
                    balanceInfo?.decimals,
                  ),
                )
              }
            >
              {strings.max}:{' '}
              {balanceAmountToUserAmount(
                balanceInfo?.amount,
                balanceInfo?.decimals,
              )}
            </span>
          }
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{strings.back}</Button>
        <Button color="primary" onClick={handleOnPress} disabled={sending}>
          {strings.stake}
        </Button>
      </DialogActions>
    </>
  );
}

function balanceAmountToUserAmount(balanceAmount: number, decimals: number) {
  return (balanceAmount / Math.pow(10, decimals)).toFixed(decimals);
}
