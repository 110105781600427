import { DialogContentText } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useQuery } from '@tanstack/react-query';
import strings from '../../../localization';
import { useWallet } from '../../../utils/wallet';
import CopyableDisplay from '../../CopyableDisplay';
import LoadingIndicator from '../../LoadingIndicator';
import type { StakingPlan } from './ActivateStake';
import { useState } from 'react';
import { P, match } from 'ts-pattern';
import { Box } from '@mui/material';
import StakingSettingsDialog from '../StakingSettingsDialog';

export default function DepositInfo({
  stakingBackendUrl,
  stakingPlan,
  onClose,
}: {
  stakingBackendUrl: string;
  stakingPlan: StakingPlan;
  onClose: () => void;
}) {
  const wallet = useWallet();
  const [showDepositAddress, setShowDepositAddress] = useState(false);

  const { data: depositAddress } = useQuery<string>({
    enabled: showDepositAddress,
    queryKey: [
      'depositAddress',
      wallet.publicKey?.toBase58(),
      stakingPlan.id,
      stakingBackendUrl,
    ],
    queryFn: async () => {
      const url = new URL(`${stakingBackendUrl}/v1/deposit`);
      url.searchParams.append('stakingPlanId', stakingPlan.id.toString());
      url.searchParams.append('domichainAddress', wallet.publicKey?.toBase58());

      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('No deposit address');
      }

      const data = await response.json();
      return data.data.depositAddress;
    },
  });

  return (
    <>
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>{strings.startStaking}</Box>
          <Box>
            <StakingSettingsDialog />
          </Box>
        </Box>
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          {strings.stakingDepositDescription}
        </DialogContentText>

        {match([showDepositAddress, depositAddress])
          .with([true, P.string], () => (
            <CopyableDisplay
              value={depositAddress}
              label={strings.depositAddress}
              autoFocus={true}
              qrCode
            />
          ))
          .with([true, P.nullish], () => <LoadingIndicator delay={0} />)
          .otherwise(() => null)}

        <CopyableDisplay
          value={stakingPlan.priceInUsdt}
          label={`USDT ${strings.amount}`}
          autoFocus={false}
          qrCode={false}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{strings.back}</Button>
        <Button
          color="primary"
          onClick={() => setShowDepositAddress(true)}
          disabled={showDepositAddress}
        >
          {strings.showDepositAddress}
        </Button>
      </DialogActions>
    </>
  );
}
