import { DialogContentText, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { Connection, PublicKey, Transaction } from '@solana/web3.js';
import { useMutation } from '@tanstack/react-query';
import { Decimal } from 'decimal.js';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import strings from '../../../localization';
import { useConnection } from '../../../utils/connection';
import { AIRDROP_BACKEND_URL } from '../../../utils/env-variables';
import { signAndSendTransaction, transferTokens } from '../../../utils/tokens';
import { TokenAccount, useTokenAccounts } from '../../../utils/tokens/hooks';
import { USDT_PROGRAM_ID } from '../../../utils/tokens/instructions';
import { estimateTransactionSize } from '../../../utils/transactions';
import { Wallet, useWallet } from '../../../utils/wallet';
import DialogForm from '../../DialogForm';

export default function UsdtExchangeDialog({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const wallet = useWallet();
  const connection = useConnection();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { data: tokenAccounts } = useTokenAccounts(USDT_PROGRAM_ID);

  const availableAmount = (tokenAccounts ?? []).reduce((acc, curr) => {
    const tokenAmount = curr.account.data.parsed.info.tokenAmount;
    return acc + tokenAmount.uiAmount;
  }, 0);

  const [inputAmount, setInputAmount] = useState('');

  const parsedInputAmount = /^\d+\.?\d*$/.test(inputAmount)
    ? new Decimal(inputAmount)
    : new Decimal(0);

  const { mutate, isLoading } = useMutation({
    mutationFn: async () => {
      if (!tokenAccounts) {
        return;
      }

      const id = enqueueSnackbar('Sending transactions...', {
        variant: 'info',
        persist: true,
      });

      try {
        const signatures = await sendTransferTransactions(
          wallet,
          connection,
          tokenAccounts,
          parsedInputAmount,
        );

        const response = await fetch(`${AIRDROP_BACKEND_URL}/v1/exchange`, {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            domichainAddress: wallet.publicKey.toBase58(),
            signatures,
          }),
        });

        if (!response.ok) {
          throw new Error('Exchange failed');
        }

        closeSnackbar(id);
        enqueueSnackbar('Transaction confirmed', {
          variant: 'success',
          autoHideDuration: 5000,
          // action: <ViewTransactionOnExplorerButton signature={signature} />,
        });
      } catch (err) {
        if (err instanceof Error) {
          closeSnackbar(id);
          enqueueSnackbar(err.message, { variant: 'error' });
          console.error(err);
        }
      }
    },
  });

  const validAmount =
    parsedInputAmount.greaterThan(50) &&
    parsedInputAmount.lessThanOrEqualTo(availableAmount);

  const isDisabled = !validAmount || isLoading;

  return (
    <>
      <DialogForm
        open={open}
        onClose={onClose}
        onSubmit={() => mutate()}
        fullWidth
      >
        <DialogTitle>{strings.exchange} USDT</DialogTitle>
        <DialogContent>
          <TextField
            label={strings.amount}
            fullWidth
            variant="outlined"
            margin="normal"
            type="number"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    onClick={() => setInputAmount(availableAmount.toFixed(9))}
                  >
                    {strings.max}
                  </Button>
                  <Typography color="primary">USDT</Typography>
                </InputAdornment>
              ),
              inputProps: {
                step: 10 ** -9,
              },
            }}
            value={inputAmount}
            onChange={(e) => setInputAmount(e.target.value.trim())}
            helperText={
              <span onClick={() => setInputAmount(availableAmount.toFixed(9))}>
                {strings.max}: {availableAmount.toFixed(9)}
              </span>
            }
          />

          <DialogContentText>Minimum USDT amount: 50 USDT</DialogContentText>
          <DialogContentText>
            After exchange, you'll immediately receive 2,000 SESA tokens and 1
            DOMI for every 2 USDT.
          </DialogContentText>
        </DialogContent>

        <DialogActions
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div>
            <Button onClick={onClose}>{strings.cancel}</Button>
            <Button type="submit" color="primary" disabled={isDisabled}>
              {strings.exchange}
            </Button>
          </div>
        </DialogActions>
      </DialogForm>
    </>
  );
}

async function getTransferDestination() {
  const response = await fetch(
    `${AIRDROP_BACKEND_URL}/v1/get-exchange-address`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    },
  );

  if (!response.ok) {
    throw new Error('Failed to fetch exchange destination address');
  }

  const responseJson = await response.json();
  const destinationPublicKey = responseJson.data?.publicKey;

  if (!destinationPublicKey) {
    throw new Error('Failed to get exchange destination address');
  }
  return new PublicKey(destinationPublicKey);
}

async function sendTransferTransactions(
  wallet: Wallet,
  connection: Connection,
  tokenAccounts: TokenAccount[],
  amount: Decimal,
) {
  const signatures: Array<string> = [];
  const destinationPublicKey = await getTransferDestination();

  let transaction = new Transaction();

  for (const { account, transferAmount } of findSuitableAccountsForTransfer(
    tokenAccounts,
    amount,
  )) {
    const transferTransaction = await transferTokens({
      connection,
      owner: wallet,
      sourcePublicKey: account.pubkey,
      destinationPublicKey,
      amount: BigInt(
        transferAmount
          .mul(10 ** account.account.data.parsed.info.tokenAmount.decimals)
          .toString(),
      ),
      mint: new PublicKey(account.account.data.parsed.info.mint),
      decimals: account.account.data.parsed.info.tokenAmount.decimals,
      overrideDestinationCheck: true,
      programId: USDT_PROGRAM_ID,
      memo: null,
    });

    const estimatedTransactionSize = estimateTransactionSize(
      new Transaction().add(transaction).add(transferTransaction),
      wallet.publicKey,
    );

    if (estimatedTransactionSize < 1232) {
      transaction.add(transferTransaction);
      continue;
    }

    signatures.push(
      await signAndSendTransaction(connection, transaction, wallet, []),
    );
    transaction = transferTransaction;
  }

  signatures.push(
    await signAndSendTransaction(connection, transaction, wallet, []),
  );
  return signatures;
}

function findSuitableAccountsForTransfer(
  accounts: TokenAccount[],
  transferAmount: Decimal,
) {
  const result: Array<{ account: TokenAccount; transferAmount: Decimal }> = [];
  const sortedAccounts = accounts
    .slice()
    .sort((a, b) =>
      Number(
        b.account.data.parsed.info.tokenAmount.uiAmount -
          a.account.data.parsed.info.tokenAmount.uiAmount,
      ),
    );

  let remainingTransferAmount = transferAmount;

  for (const account of sortedAccounts) {
    if (remainingTransferAmount.lessThanOrEqualTo(0)) {
      break;
    }
    const maxTransfer = Decimal.min(
      account.account.data.parsed.info.tokenAmount.uiAmountString,
      remainingTransferAmount,
    );
    result.push({ account, transferAmount: maxTransfer });
    remainingTransferAmount = remainingTransferAmount.sub(maxTransfer);
  }

  if (remainingTransferAmount.greaterThan(0.1)) {
    throw new Error('Insufficient funds');
  }

  return result;
}
