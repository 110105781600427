import { DialogContentText, Tab, Tabs } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import strings from '../../../localization';
import { useWallet } from '../../../utils/wallet';
import CopyableDisplay from '../../CopyableDisplay';
import DialogForm from '../../DialogForm';
import LoadingIndicator from '../../LoadingIndicator';
import { BTC_BRIDGE_URL } from '../../../utils/env-variables';

const NETWORKS = {
  domichain: 'Domichain',
  bitcoin: 'Bitcoin',
};

export default function BtcDepositDialog({ open, onClose }) {
  const wallet = useWallet();
  const [selectedNetwork, setSelectedNetwork] = useState('domichain');

  const { data: btcDepositAddress, isFetched } = useQuery({
    queryKey: ['btcDepositAddress', wallet.publicKey.toBase58()],
    enabled: open && selectedNetwork === 'bitcoin',
    staleTime: Infinity,
    queryFn: async () => {
      const response = await fetch(
        `${BTC_BRIDGE_URL}/get_address_from_db`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ domi_address: wallet.publicKey.toBase58() }),
        },
      );

      if (response.ok) {
        const address = await response.json();
        if (address.length > 0) {
          return address;
        }
      }
    },
  });

  return (
    <DialogForm
      open={open}
      onClose={onClose}
      onSubmit={undefined}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>{strings.deposit} Bitcoin</DialogTitle>
      <Tabs
        value={selectedNetwork}
        variant="fullWidth"
        onChange={(_, value) => setSelectedNetwork(value)}
        textColor="primary"
        indicatorColor="primary"
      >
        {Object.entries(NETWORKS).map(([id, name]) => (
          <Tab key={id} label={name} value={id} />
        ))}
      </Tabs>
      <DialogContent>
        {selectedNetwork === 'domichain' ? (
          <CopyableDisplay
            value={wallet.publicKey.toBase58()}
            label={strings.depositAddress}
            autoFocus={false}
            qrCode
          />
        ) : isFetched ? (
          <CopyableDisplay
            value={btcDepositAddress}
            label={strings.depositAddress}
            autoFocus={false}
            qrCode
          />
        ) : (
          <LoadingIndicator delay={0} />
        )}
        <DialogContentText>
          {strings.addressReceive} Bitcoin only from {NETWORKS[selectedNetwork]}
          . Do not send tokens from other networks!
        </DialogContentText>
        {/* <DialogContentText variant="body2">
          <Link
            // href={`${EXPLORER_URL}/account/${depositAddressStr}` + urlSuffix}
            target="_blank"
            rel="noopener"
          >
            {strings.viewOnDomiexplorer}
          </Link>
        </DialogContentText> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{strings.close}</Button>
      </DialogActions>
    </DialogForm>
  );
}
