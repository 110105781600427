import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { PublicKey } from '@solana/web3.js';
import { useQuery } from '@tanstack/react-query';
import { P, match } from 'ts-pattern';
import strings from '../../localization';
import { useConnection } from '../../utils/connection';
import { useWallet } from '../../utils/wallet';
import DialogForm from '../DialogForm';
import LoadingIndicator from '../LoadingIndicator';
import ActivateStake from './surfaces/ActivateStake';
import ActiveStakeInfo from './surfaces/ActiveStakeInfo';
import useLocalStorageState from 'use-local-storage-state';
import { STAKING_BACKEND_URL } from '../../utils/env-variables';

export default function StakeDialog({ open, onClose }) {
  const wallet = useWallet();
  const connection = useConnection();

  const [stakingBackendUrl] = useLocalStorageState('stackingBackendUrl', {
    defaultValue: STAKING_BACKEND_URL,
  });

  const { data: stakeAccount } = useQuery<PublicKey>({
    queryKey: ['stakeAccount'],
    queryFn: () => wallet.getStakeAccount(),
  });

  const { data: stakeAccountInfo, isFetched } = useQuery({
    queryKey: ['stakeAccountInfo', stakeAccount?.toBase58()],
    queryFn: async () => {
      try {
        return await connection.getStakeActivation(stakeAccount as PublicKey);
      } catch {
        return null;
      }
    },
    enabled: Boolean(stakeAccount),
    refetchInterval: 30 * 1000,
  });

  return (
    <DialogForm open={open} onClose={onClose} onSubmit={onClose} fullWidth>
      {match([isFetched, stakeAccountInfo])
        .with([true, P.not(P.nullish).select()], (stakeAccountInfo) => (
          <ActiveStakeInfo
            stakeAccount={stakeAccount!}
            stakeAccountInfo={stakeAccountInfo}
            stakingBackendUrl={stakingBackendUrl!}
            onClose={onClose}
          />
        ))
        .with([true, P.nullish], () => (
          <ActivateStake
            stakingBackendUrl={stakingBackendUrl!}
            onClose={onClose}
          />
        ))
        .otherwise(() => (
          <>
            <DialogContent>
              <LoadingIndicator delay={0} />
            </DialogContent>

            <DialogActions>
              <Button onClick={onClose}>{strings.close}</Button>
            </DialogActions>
          </>
        ))}
    </DialogForm>
  );
}
