import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
// import { registerServiceWorker } from './service-worker-register';
// import { isExtension } from './utils/utils';

const renderReactDom = () => {
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root'),
  );
};

if (window.cordova) {
  document.addEventListener('deviceready', () => renderReactDom(), false);
} else {
  renderReactDom();
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// if (window.cordova?.platformId === 'browser' && !isExtension) {
//   registerServiceWorker();
// }
