import React, { useState } from 'react';
import DialogForm from './DialogForm';
import {
  forgetWallet,
  normalizeMnemonic,
  useUnlockedMnemonicAndSeed,
} from '../utils/wallet-seed';
import DialogTitle from '@material-ui/core/DialogTitle';
import { DialogContentText } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import strings from '../localization';

export default function DeleteMnemonicDialog({ open, onClose }) {
  const [seedCheck, setSeedCheck] = useState('');
  const [mnemKey] = useUnlockedMnemonicAndSeed();
  return (
    <>
      <DialogForm
        open={open}
        onClose={onClose}
        onSubmit={() => {
          forgetWallet();
          onClose();
        }}
        fullWidth
      >
        <DialogTitle>{strings.deleteAndLogout}</DialogTitle>
        <DialogContentText style={{ margin: 20 }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {strings.wontRecover}
            <br />
            <br />
            <strong>{strings.perventLoss}</strong>
          </div>
          <TextField
            label={strings.enterSeedPhase}
            fullWidth
            variant="outlined"
            margin="normal"
            value={seedCheck}
            onChange={(e) => setSeedCheck(e.target.value)}
          />
        </DialogContentText>
        <DialogActions>
          <Button onClick={onClose}>{strings.close}</Button>
          <Button
            type="submit"
            color="primary"
            disabled={normalizeMnemonic(seedCheck) !== mnemKey.mnemonic}
          >
            {strings.delete}
          </Button>
        </DialogActions>
      </DialogForm>
    </>
  );
}
