const chinese = {
  applicationName: '多米链钱包',
  unlockWallet: '解锁钱包',
  choosePassword: '选择密码（可选）',
  pickPassword: '（可选）选择一个密码来保护您的钱包。',
  ifForgetPassword: '如果您忘记了密码，您将需要使用助记词来恢复您的钱包。',
  restoreExistingWallet: '恢复现有钱包',
  createNewWallet: '创建新钱包',
  createToHold: '创建一个新钱包来存放 Domichain 和 DPL 代币',
  seedSafePlace: '请写下以下十二个单词并妥善保管：',
  privateKeysStored:
    '您的私钥仅存储在您当前的计算机或设备上。如果您的浏览器存储被清除或者您的设备损坏或丢失，您将需要这些词来恢复您的钱包。',
  iHaveSaved: '我把这些话保存在一个安全的地方。',
  downloadMnemonic: '下载备份助记词文件（必填）',
  pleaseReenter: '请重新输入您的助记词以确认您已保存它。',
  pleaseType: '请输入您的助记词以确认',
  newPassword: '新密码',
  confirmPassword: '确认密码',
  back: '后退',
  createWallet: '创建钱包',
  unlockingWallet: '解锁钱包...',
  walletUnlocked: '钱包解锁',
  password: '密码',
  keepWalletUnlocked: '保持钱包解锁',
  restoreExisting: '恢复现有钱包',
  restoreUsing:
    '使用您的十二个或二十四个种子词恢复您的钱包。请注意，这将删除此设备上的所有现有钱包。',
  noHardwareWallet:
    '不要在此处输入您的硬件钱包助记词。创建网络钱包后，可以选择连接硬件钱包。',
  mnemonicFailed: '助记词验证失败。请输入有效的 BIP 39 助记词。',
  seedWords: '种子词',
  newPasswordOptional: '新密码（可选）',
  cancel: '取消',
  next: '下一个',
  addAccount: '新增帐户',
  name: '姓名',
  importPrivateKey: '导入私钥',
  pastePrivateKey: '导入私钥',
  add: '添加',
  close: '关闭',
  addCustomNetwork: '添加自定义网络',
  url: '网址',
  done: '完毕',
  continue: '继续',
  export: '出口',
  delete: '删除',
  send: '发送',
  receive: '收到',
  unlock: '开锁',
  confirmAddress: '确认您的钱包地址',
  checkLedger: '检查您的分类帐并确认显示的地址是您选择的地址。然后点击“完成”。',
  addHardwareWallet: '添加硬件钱包',
  connectLedger:
    '连接您的账本并打开 Domichain 应用程序。准备就绪后，单击“继续”。',
  loadingFromHardwareWallet: '从您的硬件钱包加载帐户',
  copied: '已复制',
  copyToClipboard: '复制到剪贴板',
  editAccountName: '修改账户名称',
  auxiliaruAccount: '这是一个辅助令牌帐户。',
  viewOnDomiexplorer: '在 Domiexplorer 上查看',
  viewDetails: '查看详情',
  tokenName: '代币名称',
  tokenSymbol: '代币符号',
  mainAccount: '主账户',
  account: '帐户',
  refresh: '刷新',
  wontRecover:
    '没有助记词和账户私钥，您将无法恢复当前账户。此操作将从您的浏览器中删除所有当前帐户。',
  perventLoss:
    '为防止资金损失，请确保您拥有所有活期账户的助记词和私钥。您可以通过在用户菜单中选择“导出助记词”来查看它。',
  enterSeedPhase: '请输入您的助记词以确认',
  deleteAndLogout: '删除助记词&退出',
  editAccount: '编辑帐户',
  addressReceive: '该地址可用于接收',
  depositAddress: '存款地址',
  enterDomichainAddress: '输入 Domichain 地址',
  enterDPLAddress: '输入 DPL 代币或 Domichain 地址',
  amount: '数量',
  max: '最大限度',
  recipientAddress: '收件人地址',
  use1: '请使用',
  use2: '虫洞传送桥',
  use3: '桥接您的资产。',
  save: '节省',
  exportAccount: '出口账户',
  privateKey: '私钥',
  reveal: '揭示',
  mnemonic: '助记词',
  connect: '连接',
  exportMnemonic: '导出助记词',
  importHardwareWallet: '进口硬件钱包',
  selectAccount: '选择账户',
  editCustomEndpoint: '编辑自定义端点',
  addCustomEndpoint: '添加自定义端点',
  hardwareWallet: '硬件钱包',
  selectNetwork: '选择网络',
  selectLanguage: '选择语言',
  connections: '连接',
  manageConnections: '管理连接',
  wallet: '钱包',
  walletBalance: '钱包余额',
  expandView: '展开视图',
  appName: 'DWallet',
  disconnect: '断开',
  connectedDapps: '连接的 Dapps',
  sendRequest: '发送请求',
  waitingForConfirmation: '等待确认中',
  withdrawFunds: '提款',
  deposit: '订金',
  metamaskConnected: '已连接元掩码',
  transactionPending: '待处理交易',
  language: '语言',
  confirmMnenonic: '确认助记词',
  creatingWallet: '创建钱包...',
  walletCreated: '钱包创建',
  time: '时间',
  txHash: '交易哈希',
  type: '类型',
  transfer: '转移',
  other: '其他',
  approve: '批准',
  deny: '拒绝',
  connectWalletDialogQuestion: '允许这个Dapp访问你的Domichain账户？',
  connectWalletDialogNotice:
    '所有交易将被自动批准，只与你信任的网站连接。你可以随时在 "连接的Dapps "部分改变这一点。',
  allowTransactionDialogQuestion: '允许这个Dapp发送一个交易？',
  airdrop: '空投',
  addressHasNoFunds: '该地址没有资金。你确定它是正确的吗？',
  mintNewToken: '铸造新令牌',
  mintNewTokenDescription:
    '生成并接收新的无价值令牌。此操作需要 DOMI 支付交易费用。',
  tokenDecimals: '代币小数',
  stake: '质押',
  yourStake: '您的质押',
  stakeAccount: '质押账户',
  status: '状态',
  balance: '余额',
  activeStake: '活跃质押',
  inactiveStake: '非活跃质押',
  reward: '奖励',
  unstake: '解除质押',
  withdrawStake: '提取质押资金',
  startStaking: '开始质押',
  stakingChoosePlanDescription:
    '通过选择下面的计划开始DOMI的质押，并获得奖励。',
  stakingDepositDescription:
    '将USDT存入BNB智能链上给定的存款地址，并指定令牌数量。\n\n一旦存款成功，您将自动符合进行质押的条件。',
  stakingDelegateDescription: '选择要与验证节点一起进行DOMI投票权委托的金额。',
  stakingActivatingDescription:
    '一旦费用账户激活，您所持有的DOMI将在未来几天内开始赚取奖励。',
  stakingActiveDescription:
    '您当前正在与验证节点进行投票权委托。要使用这些资金，您需要解除投票权委托。',
  stakingPlan: '计划',
  stakingAnnualFee: '首年费用',
  stakingProduct: '产品',
  stakingAnnualRoi: '年回报率',
  stakingLimit: '限制',
  stakingUrl: '定标 URL',
  unlimited: '无限',
  validator: '验证节点',
  activating: '激活',
  active: '活跃',
  deactivating: '停用',
  inactive: '不活动',
  showDepositAddress: '支付',
  confirmUnstakeTitle: '确认解除抵押',
  confirmUnstakeDescription:
    '您确定要取消认购吗？您将停止赚取任何奖励，但可以提取您的资金。',
  installPwaTitle: '安装 DWallet',
  installPwaDescription: '在设备上安装该应用程序，即可随时轻松访问。',
  installPwaStepOne: '1. 点击 "分享" 按钮',
  installPwaStepTwo: '2. 选择 "添加到主屏幕"',
  installPwaStepThree: '3. 到主屏幕上，找到DWallet，点击DWallet。',
  redPacketTitle: '新年快乐！',
  redPacketAvailable: '点击这里领取你的免费红包礼物',
  redPacketUnavailable: '请在%s再来领取另一份礼物',
  redPacketSnackbar: '你已经从红包礼物中获得了%s个DOMI！',
  errorOccurredTryAgainLater: '今天的空投发完了，明天再来领取',
  settings: '设置',
  free: '免费',
  welcomeAirdropTitle: '欢迎新用户!',
  welcomeAirdropAvailable: '按此获得我们赠送的 1 份免费 DOMI',
  welcomeAirdropUnavailable: '请在%s再来领取另一份礼物',
  welcomeAirdropSnackbar: '您已收到 %s DOMI 作为欢迎礼物！',
  usdtDepositNotice:
    '此地址只能用于接收来自 %s 的 USDT。请勿发送其他网络的代币!',
  usdtContractAddress: 'USDT 合约地址:',
  exchange: '交换',
  loading: '加载中...',
  expectedFee: '预计费用', // Expected fee N BTC
  youWillReceive: '您将收到', // You will receive N BTC
  selectTheFee: '选择费用',
  fastest: '最快的', // Fastest N sat/vB
  normal: '普通的', // Normal N sat/vB
  economy: '经济', // Economy N sat/vB
  sendingTransaction: '正在发送交易...',
  transactionConfirmed: '交易已确认',
  failedToSignAndSendAMultisigTransaction: '无法签署并发送多重签名交易',
  noAccountsFound: '未找到任何账户',
  requestFailed: '请求失败',
  noFeeRates: '无费率',
  insufficientFunds: '不充足的资金',
  tenMinutes: '~10分钟',
  thirtyMinutes: '~30分钟',
  oneHour: '~1小时',
};

export default chinese;
