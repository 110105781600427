import React, { useContext, useEffect, useMemo } from 'react';
import {
  AccountInfo,
  clusterApiUrl,
  Connection,
  PublicKey,
} from '@solana/web3.js';
import tuple from 'immutable-tuple';
import * as anchor from '@project-serum/anchor';
import { useRefEqual } from './utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { queryClient } from './query-client';
import { NODE_URL } from './env-variables';
import useLocalStorageState from 'use-local-storage-state';

const ConnectionContext = React.createContext<{
  endpoint: string;
  setEndpoint: (string) => void;
  connection: Connection;
} | null>(null);

export const MAINNET_URL = NODE_URL;

// No backup url for now. Leave the variable to not break wallets that
// have saved the url in their local storage, previously.
export const MAINNET_BACKUP_URL = NODE_URL;

export function ConnectionProvider({ children }) {
  const [endpoint, setEndpoint] = useLocalStorageState('connectionEndpoint', {
    defaultValue: MAINNET_URL!,
  });

  const connection = useMemo(
    () => new Connection(endpoint, 'confirmed'),
    [endpoint],
  );

  return (
    <ConnectionContext.Provider value={{ endpoint, setEndpoint, connection }}>
      {children}
    </ConnectionContext.Provider>
  );
}

export function useConnection(): Connection {
  let context = useContext(ConnectionContext);
  if (!context) {
    throw new Error('Missing connection context');
  }
  return context.connection;
}

export function useConnectionConfig() {
  let context = useContext(ConnectionContext);
  if (!context) {
    throw new Error('Missing connection context');
  }
  return { endpoint: context.endpoint, setEndpoint: context.setEndpoint };
}

export function useIsProdNetwork() {
  let context = useContext(ConnectionContext);
  if (!context) {
    throw new Error('Missing connection context');
  }
  return (
    context.endpoint === MAINNET_URL || context.endpoint === MAINNET_BACKUP_URL
  );
}

export function useDomichainExplorerUrlSuffix() {
  const context = useContext(ConnectionContext);
  if (!context) {
    throw new Error('Missing connection context');
  }
  const endpoint = context.endpoint;
  if (endpoint === 'https://api.devnet.domichain.io') {
    return '?cluster=devnet';
  } else if (endpoint === 'https://api.testnet.domichain.io') {
    return '?cluster=testnet';
  }
  return '';
}

export function useAccountInfo(publicKey?: PublicKey) {
  const connection = useConnection();
  const queryClient = useQueryClient();
  const { data: accountInfo, isFetched: loaded } = useQuery({
    queryKey: ['accountInfo', publicKey?.toBase58(), connection.rpcEndpoint],
    queryFn: async () => connection.getAccountInfo(publicKey!),
    enabled: !!publicKey,
  });
  useEffect(() => {
    if (!publicKey) {
      return;
    }
    const id = connection.onAccountChange(publicKey, (info) => {
      queryClient.setQueryData(
        ['accountInfo', publicKey.toBase58(), connection.rpcEndpoint],
        () => info,
      );
      queryClient.invalidateQueries({
        predicate: ({ queryKey }) =>
          queryKey[0] !== 'accountInfo' && queryKey[1] !== publicKey.toBase58(),
      });
    });
    return () => {
      connection.removeAccountChangeListener(id);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connection, publicKey?.toBase58() ?? '']);
  return [
    useRefEqual(
      accountInfo,
      (oldInfo, newInfo) =>
        !!oldInfo &&
        !!newInfo &&
        oldInfo.data.equals(newInfo.data) &&
        oldInfo.lamports === newInfo.lamports,
    ),
    loaded,
  ];
}

export function refreshAccountInfo(connection, publicKey) {
  queryClient.refetchQueries({
    queryKey: ['accountInfo', publicKey.toBase58(), connection.rpcEndpoint],
  });
}

export function setInitialAccountInfo(connection, publicKey, accountInfo) {
  queryClient.setQueryData(
    ['accountInfo', publicKey.toBase58(), connection.rpcEndpoint],
    (previous) => previous ?? accountInfo,
  );
}

export async function getMultipleSolanaAccounts(
  connection: Connection,
  publicKeys: PublicKey[],
): Promise<
  Array<null | { publicKey: PublicKey; account: AccountInfo<Buffer> }>
> {
  return anchor.utils.rpc.getMultipleAccounts(connection, publicKeys);
}
