import { makeStyles } from '@material-ui/core/styles';
import { useState, useRef, useCallback, useEffect } from 'react';
import { useWallet, useWalletSelector } from '../utils/wallet';
import * as bs58 from 'bs58';
import { IconButton, Tooltip } from '@material-ui/core';
import { useIsExtensionWidth } from '../utils/utils';
import { ArrowForwardRounded } from '@material-ui/icons';
import { useConnectedWallets } from '../utils/connected-wallets';
import strings from '../localization';
import { chromeLocalStorage } from '../utils/chrome-storage-polyfill';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  urlField: {
    flex: 1,
    padding: '12px 16px',
  },
  button: {
    marginLeft: 8,
    marginRight: 8,
  },
}));

export default function Browser() {
  const classes = useStyles();
  const isExtensionWidth = useIsExtensionWidth();
  const iconSize = isExtensionWidth ? 'small' : 'medium';

  const connectedWallets = useConnectedWallets();
  const { accounts } = useWalletSelector();
  const wallet = useWallet();

  const currentBrowserUrl = useRef();
  const [browserRef, setBrowserRef] = useState();
  const [rawUrlInput, setRawUrlInput] = useState();

  const handleOnLoadStop = useCallback(
    /** @param {InAppBrowserEvent} event */
    (event) => {
      currentBrowserUrl.current = event.url;

      if (browserRef) {
        browserRef.executeScript({
          code: `
            window.dwallet = {
              postMessage: function dwalletPostMessage(message) {
                message.metadata = { dappTitle: document.title };
                webkit.messageHandlers.cordova_iab.postMessage(JSON.stringify(message));
              }
            };
          `,
        });
      }
    },
    [browserRef],
  );

  const handleConnect = useCallback(
    async (respondWith) => {
      const { origin } = new URL(currentBrowserUrl.current);
      const connectedWallet = connectedWallets?.[origin];

      const mustConnect =
        connectedWallet?.publicKey !== wallet.publicKey.toBase58();

      const account = accounts.find((account) =>
        account.address.equals(wallet.publicKey),
      );

      const connectWallet = () => {
        chromeLocalStorage.set({
          connectedWallets: {
            ...connectedWallets,
            [origin]: {
              publicKey: wallet.publicKey.toBase58(),
              selector: account.selector,
              autoApprove: connectedWallet?.autoApprove ?? true,
            },
          },
        });

        respondWith({
          method: 'connected',
          params: {
            publicKey: wallet.publicKey.toBase58(),
            autoApprove: connectedWallet?.autoApprove ?? true,
          },
        });
      };

      const handleConfirmation = (buttonIndex) => {
        if (buttonIndex === 1) {
          connectWallet();
        } else {
          respondWith({ method: 'disconnected' });
        }
      };

      if (mustConnect) {
        const walletAddress = wallet.publicKey.toBase58().slice(0, 16);
        const confirmationMessage =
          `${strings.connectWalletDialogQuestion}\n\n` +
          `${strings.wallet}: ${walletAddress}...\n\n` +
          strings.connectWalletDialogNotice;

        // FIXME: dwallet-wallet-adapter enforces a 10-second window to receive a response,
        // otherwise it just timeouts and rejects the entire wallet handshake process.
        navigator.notification.confirm(
          confirmationMessage,
          handleConfirmation,
          strings.appName,
          [strings.connect, strings.cancel],
        );
      } else {
        connectWallet();
      }
    },
    [accounts, connectedWallets, wallet?.publicKey],
  );

  const handleDisconnect = useCallback(async (respondWith) => {
    const { origin } = new URL(currentBrowserUrl.current);

    chromeLocalStorage.get('connectedWallets', (result) => {
      delete result.connectedWallets[origin];
      chromeLocalStorage.set({
        connectedWallets: result.connectedWallets,
      });
    });

    respondWith({
      method: 'disconnected',
    });
  }, []);

  const handleSignTransactions = useCallback(
    async (params, respondWith) => {
      const signTransactions = async () => {
        if ('message' in params) {
          const message = Buffer.from(bs58.decode(params.message));
          const signature = await wallet.createSignature(message);

          respondWith({
            result: {
              publicKey: wallet.publicKey.toBase58(),
              signature,
            },
          });
        } else if ('messages' in params) {
          let signatures = [];

          const messages = params.messages.map((message) =>
            Buffer.from(bs58.decode(message)),
          );

          if (wallet.type === 'ledger') {
            for (const message of messages) {
              signatures.push(await wallet.createSignature(message));
            }
          } else {
            signatures = await Promise.all(
              messages.map(wallet.createSignature),
            );
          }

          respondWith({
            result: {
              publicKey: wallet.publicKey.toBase58(),
              signatures,
            },
          });
        } else {
          throw new Error('Invalid payload');
        }
      };

      const handleConfirmation = async (buttonIndex) => {
        if (buttonIndex === 1) {
          await signTransactions();
        } else {
          respondWith({ error: 'Transaction cancelled' });
        }
      };

      const { origin } = new URL(currentBrowserUrl.current);
      const connectedWallet = connectedWallets?.[origin];

      if (!connectedWallet?.autoApprove) {
        const confirmationMessage =
          `${strings.allowTransactionDialogQuestion}\n\n` +
          `${strings.wallet}: ${wallet.publicKey.toBase58().slice(0, 16)}...`;

        navigator.notification.confirm(
          confirmationMessage,
          handleConfirmation,
          strings.appName,
          [strings.approve, strings.deny],
        );
      } else {
        await signTransactions();
      }
    },
    [wallet, connectedWallets],
  );

  const handleWebViewMessage = useCallback(
    /** @param {InAppBrowserEvent} event */
    async (event) => {
      console.debug('Message from WebView:', event);

      if (!wallet || !event?.data) {
        return;
      }

      const data = event.data;
      const method = data.method;

      const respondWith = (response) => {
        const responseWithId = { id: data.id, ...response };

        if (browserRef) {
          browserRef.executeScript({
            code: `
              window.postMessage(${JSON.stringify(responseWithId)});
              console.debug('Message from DWallet:', ${JSON.stringify(
                responseWithId,
              )});
            `,
          });
        }
      };

      switch (method) {
        case 'connect':
          return await handleConnect(respondWith);
        case 'disconnect':
          return await handleDisconnect(respondWith);
        case 'sign':
        case 'signTransaction':
        case 'signAllTransactions':
          return await handleSignTransactions(data.params, respondWith);
        default:
          return respondWith({ error: 'Unsupported method' });
      }
    },
    [
      wallet,
      browserRef,
      handleConnect,
      handleDisconnect,
      handleSignTransactions,
    ],
  );

  const navigateToPage = useCallback(
    /** @param {string} url */
    (url) => {
      const sanitizedUrl = encodeURI(
        !/^https?:\/\//i.test(url) ? `https://${url}` : url,
      );

      let settings = {
        location: true,
        fullscreen: false,
        toolbarcolor: '#2196f3',
        navigationbuttoncolor: '#ffffff',
        closebuttoncolor: '#ffffff',
      };

      if (window.cordova?.platformId === 'ios') {
        settings.toolbarposition = 'top';
        delete settings.toolbarcolor;
      }

      const settingsString = Object.entries(settings)
        .map(([key, value]) => {
          const serializedValue =
            typeof value === 'boolean' ? (value ? 'yes' : 'no') : value;
          return `${key}=${serializedValue}`;
        })
        .join(',');

      const newBrowserRef = window.cordova.InAppBrowser.open(
        sanitizedUrl,
        '_blank',
        settingsString,
      );
      setBrowserRef(newBrowserRef);
      newBrowserRef.show();
    },
    [],
  );

  useEffect(() => {
    browserRef?.addEventListener('loadstop', handleOnLoadStop);
    browserRef?.addEventListener('message', handleWebViewMessage);

    return () => {
      browserRef?.removeEventListener('loadstop', handleOnLoadStop);
      browserRef?.removeEventListener('message', handleWebViewMessage);
    };
  }, [browserRef, handleOnLoadStop, handleWebViewMessage]);

  return (
    <div className={classes.root}>
      <input
        type="text"
        placeholder="Enter URL..."
        className={classes.urlField}
        variant="h6"
        component="h2"
        value={rawUrlInput}
        onChange={(event) => {
          setRawUrlInput(event.target.value);
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            navigateToPage(event.target.value);
          }
        }}
      />
      <Tooltip arrow>
        <IconButton
          size={iconSize}
          className={classes.button}
          onClick={() => {
            navigateToPage(rawUrlInput);
          }}
        >
          <ArrowForwardRounded />
        </IconButton>
      </Tooltip>
    </div>
  );
}
