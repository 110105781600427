import LocalizedStrings from 'react-localization';

import chinese from './localization/chinese';
import english from './localization/english';
import korean from './localization/korean';

let strings = new LocalizedStrings({
  en: english,
  zh: chinese,
  ko: korean,
});

export default strings;
